import Title from "./Title";
import gif1 from "../assets/gifs/gif_desk.mp4";
import gif2 from "../assets/gifs/gif_mob.mp4";
import { useRef } from "react";
import { useEffect } from "react";

function Sp02(props) {
  const vid1Ref = useRef();
  const vid2Ref = useRef();

  useEffect(() => {
    vid1Ref.current.play();
    vid2Ref.current.play();
  }, []);
  return (
    <div className="container containerh manrope">
      <div className="flex justify-between mfc">
        <div className="gif_cnt">
          <div className="gif_cn flex justify-center align-center">
            <video
              ref={vid1Ref}
              disablePictureInPicture
              className="desktop"
              src={gif1}
              autoPlay
              loop
              muted
            ></video>
            <video
              ref={vid2Ref}
              disablePictureInPicture
              className="mobile"
              src={gif2}
              autoPlay
              loop
              muted
            ></video>
          </div>
        </div>
        <div className="abml">
          <Title title="INVEST2ENTERPRISE." />
          <p>
            The cADRE Business, also known as ‘INVEST2ENTERPRISE’ service, is a
            Manufacturing Franchise Business that can organically and actively
            generate revenue for women and families in rural areas. The cATT
            part of the cADRE business is most exciting; cATT is a
            micro-industry on wheels, self-sufficient, advanced, connected,
            highly secure, and a unified class 10000 cleanroom to carry out
            manufacturing or assembly at one’s doorstep. The entrepreneur,
            admitted into cADRE as a Micro-Entrepreneur(ME), will manufacture
            the innovations of csPILLAI alongside her employees under the
            company’s guidance and training.
          </p>
        </div>
      </div>
      <br />
      <div className="flex mfc justify-between">
        {/* <div className="dmk">
          <Title title="Our Vision" />
          <p>
            “csPILLAI’s vision is to create 10k small start-up estates and help
            the world. With these start-up estates, company aims at building
            start-ups, scale-ups, entrepreneurships, and people.
          </p>
        </div> */}
        <div className="dmk">
          {/* <Title title="csPILLAI" /> */}
          <p>
            csPILLAI offers innovative cATT units to women (micro)entrepreneurs
            at their doorstep at no cost through Manufacturing Franchise
            Agreement(MFA); this means that csPILLAI admits them into the cADRE
            manufacturing franchise business at a zero-cost strategy. csPILLAI
            provides raw materials and 50% markup, ultimately guaranteeing
            regular monthly earnings once the manufacturing and supply activity
            advances.
          </p>
        </div>
      </div>
      <br />
      <br />
    </div>
  );
}

export default Sp02;
