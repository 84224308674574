function Title({ subtitle = "csPILLAI", title, ...props }) {
  return (
    <div {...props} className="title manrope">
      <span style={{ color: "#F57059", fontWeight: "700", fontSize: "0.6em" }}>
        {subtitle}
      </span>
      <h2 style={{ fontWeight: "800" }}>{title}</h2>
    </div>
  );
}

export default Title;
