import { createRef } from "react";
import { useEffect } from "react";
import { useState } from "react";
import useClient from "../../../hooks/useClient";
import { useLocation } from "react-router-dom";
import urls from "../../../api/urls";
import {
  days,
  getDay,
  getEnd,
  getStart,
  getTotalHours,
} from "../../../utils/dates";
import Loader from "../../../components/Loader";
import { useContext } from "react";
import InternContext from "../../../contexts/InternContext";
import Pdf from "react-to-pdf";
import Button from "../../../components/Button";

function ViewReport() {
  const [isDownloadLoading, setIsDownloadLoading] = useState(false);
  const [logs, setLogs] = useState([]);
  const { isLoading, get } = useClient();
  const { pathname } = useLocation();
  const [report, setReport] = useState(null);
  const { setRouteTitle, reports, user } = useContext(InternContext);
  const ref = createRef();

  const getReport = async () => {
    const date = pathname.replace("/internship/reports/", "");
    if (!date) return;

    const { data, error } = await get(urls.interns.activity.activities + date);
    if (error) return alert(error.message);
    setLogs(data.data.activities);
  };

  useEffect(() => {
    getReport();
    setRouteTitle(`Report: ${pathname.replace("/internship/reports/", "")}`);
    setReport(
      reports.find(
        (r) => r.week === pathname.replace("/internship/reports/", "")
      )
    );
  }, []);

  if (isLoading) return <Loader loading />;

  return (
    <div className="report_cnt">
      <div ref={ref} className="report">
        <h2>Interns Weekly Log and Time Sheet</h2>
        <h3>
          Intern Name: {user.firstName} {user.lastName}
        </h3>
        <br />
        {/* <div style={{ padding: 20, borderTopWidth: 1 }} className="box flex">
          <div className="dates">
            <h3>Total Hours Worked</h3>
            <h3>{getTotalHours(logs)}</h3>
          </div>
          <div>
            <span>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Velit
              ultricies semper mattis duis vulputate quisque commodo luctus et.
              Aliquam leo.
            </span>
          </div>
        </div> */}

        <div style={{ borderTopWidth: 1 }} className={`box flex `}>
          <div className="dates">
            <h3>Total Hours Worked</h3>
            <h3 style={{ textAlign: "center" }}>{getTotalHours(logs)}</h3>
          </div>
          <div>
            <span>
              {/* Lorem ipsum dolor sit amet, consectetur adipiscing elit. Velit
              ultricies semper mattis duis vulputate quisque commodo luctus et.
              Aliquam leo. */}
            </span>
          </div>
        </div>

        {days
          .filter((d) => d !== "Sunday" && d !== "Saturday")
          .map((d, idx) => {
            const record = logs.find(
              (a) => days[new Date(a.createdAt).getDay()] === d
            );

            return (
              <div className={`box flex `} key={idx}>
                <div className="dates">
                  <h3>{d}</h3>
                  {/* {console.log(logs[idx]?.createdAt)} */}
                  <span>
                    {(() => {
                      const d = new Date(
                        pathname.replace("/internship/reports/", "")
                      );
                      d.setDate(d.getDate() + idx);
                      return getDay(d);
                    })()}
                  </span>
                  <span>
                    From {getStart(record, "_____")} to{" "}
                    {getEnd(record, "_____")}
                  </span>
                </div>
                <div>
                  <span>{record?.description || "No record"}</span>
                </div>
              </div>
            );
          })}
        <div style={{ padding: 20 }} className="box">
          <h3>Mentors Comments</h3>
          <span>{report?.comment || "No comment"}</span>

          {report?.approved && (
            <div className="rpap">
              <span>Signed and Approved</span>{" "}
              <svg
                width="21"
                height="21"
                viewBox="0 0 21 21"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M0 10.5C0 7.71523 1.10625 5.04451 3.07538 3.07538C5.04451 1.10625 7.71523 0 10.5 0C13.2848 0 15.9555 1.10625 17.9246 3.07538C19.8938 5.04451 21 7.71523 21 10.5C21 13.2848 19.8938 15.9555 17.9246 17.9246C15.9555 19.8938 13.2848 21 10.5 21C7.71523 21 5.04451 19.8938 3.07538 17.9246C1.10625 15.9555 0 13.2848 0 10.5H0ZM9.9008 14.994L15.946 7.4368L14.854 6.5632L9.6992 13.0046L6.048 9.9624L5.152 11.0376L9.9008 14.9954V14.994Z"
                  fill="#38CB89"
                />
              </svg>
            </div>
          )}
        </div>
      </div>
      <br />
      <Pdf
        onComplete={() => setIsDownloadLoading(false)}
        targetRef={ref}
        filename={`Report_${pathname.replace("/internship/reports/", "")}.pdf`}
      >
        {({ toPdf }) => (
          <Button
            loading={isDownloadLoading}
            onClick={() => {
              setIsDownloadLoading(true);
              toPdf();
            }}
            className="gradient"
            title="Dowload PDF"
          />
        )}
      </Pdf>
      <br />
      <br />
      <br />
    </div>
  );
}

export default ViewReport;
