import { useState } from "react";
import urls from "../../../api/urls";
import "../../../css/login.css";
import useClient from "../../../hooks/useClient";
import { Link } from "react-router-dom";

function RecoverAccoount(props) {
  const [email, setEmail] = useState("");
  // const [email, setEmail] = useState("ismaildalhatu442@gmail.com");
  const [errorMessage, setErrorMessage] = useState("");
  const { isLoading, post } = useClient();

  const handleLogin = async (e) => {
    e.preventDefault();
    setErrorMessage("");
    if (!email) return setErrorMessage("Provide an email");
    const { data, error } = await post(urls.interns.recoverAccount, {
      email,
    });
    if (error) return setErrorMessage(error.message);
    alert(data.message);
  };

  return (
    <div className="container_auth manrope">
      <form onSubmit={handleLogin} id="resetForm">
        <div className="logo">
          <svg
            width="49"
            height="54"
            viewBox="0 0 49 54"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M38.9375 12.2154L24.6332 7.56553L8.9212 16.0602L10.9185 36.6331L24.6332 45.3932L39.8125 35.1731L40.4783 30.9257L24.6332 41.1458V21.9002L49 8.72527L48.3342 14.0692L29.4266 24.6875L29.0272 32.5185L47.9348 21.9002L45.6712 38.624L24.6332 53.2241L4.26087 39.8186L0 11.9456L23.8342 0L48.3342 6.98021L38.9375 12.2154Z"
              fill="url(#paint0_linear_24_70)"
            />
            <defs>
              <linearGradient
                id="paint0_linear_24_70"
                x1="24.2337"
                y1="2.38911"
                x2="24.2337"
                y2="53.2241"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#F64C3B" />
                <stop offset="1" stopColor="#B80CB6" />
              </linearGradient>
            </defs>
          </svg>
        </div>

        <h2>Recover Account</h2>
        <span>Enter your email to reset your password</span>
        <label htmlFor="email">Email</label>
        <input
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
          id="email"
          type="email"
          name="email"
        />
        <br />
        <Link className="link_sr" to="/internship/login">
          Remembered password log in
        </Link>
        <p id="message" className="message">
          {errorMessage}
        </p>
        <input
          disabled={isLoading}
          id="submit"
          type="submit"
          value={isLoading ? "Loading..." : "Request"}
        />
      </form>
    </div>
  );
}

export default RecoverAccoount;
