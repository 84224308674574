import { useContext } from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import urls from "../../api/urls";
import InternContext from "../../contexts/InternContext";
import useClient from "../../hooks/useClient";
import Button from "../Button";
import Loader from "../Loader";

function RenderAgreemnt({ stat = 0, className = "" }) {
  const [agreement, setAgreement] = useState(null);
  const { isLoading, get, post } = useClient();
  const { agreement: agg, user } = useContext(InternContext);

  const getAgreement = async () => {
    const { data, error } = await get(urls.interns.getAgreement);
    if (error) return;
    setAgreement(data.data.agreement);
  };

  useEffect(() => {
    getAgreement();
  }, []);

  const navigate = useNavigate();

  return (
    <div className={className}>
      <span className="hlo">Internship Contract Agreement</span>
      <Button
        disabled={(agg && !agg.initiated) || !user.verifications.account}
        onClick={() =>
          agg
            ? agg.initiated
              ? window.open(agg.eSign.eSign[0].uploadUrl)
              : () => {}
            : navigate("/internship/sign-agreement")
        }
        title={
          agg
            ? agg.initiated
              ? "Download Document"
              : "IN PROGRESS"
            : "Sign agreement"
        }
        className="gradient bgn_download_agg justify-center align-center flex align-center"
      />
      {/* <div
        onClick={() => {
          if (agreement === null || !agreement?.initiated) {
            initiateAgreement();
          }
        }}
        style={{
          cursor: agreement?.initiated || isLoading ? "not-allowed" : "pointer",
        }}
        className={`gradient bgn_download justify-center align-center flex align-center ${
          agreement?.initiated || isLoading ? "disabled" : ""
        }`}
      >
        {isLoading ? (
          <Loader className="btn_loader" loading />
        ) : agreement === null ? (
          <span>Sign Agreement</span>
        ) : !agreement?.initiated ? (
          <span>In progress</span>
        ) : (
          <>
            <div className="svg_d">
              <svg
                width="13"
                height="13"
                viewBox="0 0 13 13"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6.5 10.5938L3.0625 6.5H9.9375L6.5 10.5938ZM5.25 0.25H7.75V1.5H5.25V0.25ZM5.25 2.125H7.75V3.375H5.25V2.125Z"
                  fill="white"
                />
                <path
                  d="M5.25 4H7.75V7.4375H5.25V4ZM0.875 11.5H12.125V12.75H0.875V11.5Z"
                  fill="white"
                />
              </svg>
            </div>
            <span>Download</span>
          </>
        )}
      </div> */}
    </div>
  );
}

export default RenderAgreemnt;
