import Title from "../components/Title";

function Token(props) {
  return (
    <div>
      <div id="home" style={{ height: 60 }}></div>
      <div className="container manrope">
        <Title title="Personal Information" />
        <p>Make sure you upload all the necessary documents.</p>
      </div>
    </div>
  );
}

export default Token;
