export const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
export const days = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];
export const weekDays = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
];

const getNumber = (n) => (n < 12 ? `${n}am` : `${n - 12}pm`);
const getNumber2 = (n) => (n < 10 ? `0${n}` : n);

export const getMonday = () => {
  const date = new Date();
  while (date.getDay() !== 1) {
    date.setDate(date.getDate() - 1);
  }
  return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
};

export const getHoursWorked = (activity) => {
  if (!activity) return 0;
  return (
    new Date(activity.endDate).getHours() -
    new Date(activity.startDate).getHours()
  );
};

export const getStart = (activity, placeholder = "No record") => {
  if (!activity) return placeholder;
  return getNumber(new Date(activity.startDate).getHours());
};
export const getEnd = (activity, placeholder = "No record") => {
  if (!activity) return placeholder;
  return getNumber(new Date(activity.endDate).getHours());
};

export const getWeeks = (agreement) => {
  const mondays = [];
  if (!agreement) return mondays;
  const date1 = new Date(agreement.createdAt);
  const date2 = new Date(agreement.endDate);
  while (date1 < date2) {
    if (date1.getDay() === 1) {
      mondays.push(
        `${date1.getFullYear()}-${getNumber2(
          date1.getMonth() + 1
        )}-${getNumber2(date1.getDate())}`
      );
    }
    date1.setDate(date1.getDate() + 1);
  }

  return mondays;
};

export const getTo = (d) => {
  const date = new Date(d);
  while (date.getDay() !== 5) {
    date.setDate(date.getDate() + 1);
  }
  return `${date.getFullYear()}-${getNumber2(date.getMonth() + 1)}-${getNumber2(
    date.getDate()
  )}`;
};

export const getTotalHours = (d) => {
  let hours = 0;
  d.forEach((e) => {
    hours += getHoursWorked(e);
  });
  return hours;
};

export const getDay = (stmp) => {
  const date = new Date(stmp);
  return `${date.getFullYear()}-${getNumber2(date.getMonth() + 1)}-${getNumber2(
    date.getDate()
  )}`;
};

export const canSubmitToday = (workDays) => {
  if (!workDays) return false;
  const { from, to } = workDays;
  const today = days[new Date().getDay()];
  const wdays = weekDays.slice(
    weekDays.indexOf(from),
    weekDays.indexOf(to) + 1
  );
  return wdays.includes(today);
};
