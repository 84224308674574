import Title from "./Title";
import i01 from "../assets/svg/obj/01.svg";
import i02 from "../assets/svg/obj/02.svg";
import i03 from "../assets/svg/obj/03.svg";
import i04 from "../assets/svg/obj/04.svg";
import "../css/objectives.css";

const objectives = [
  {
    svg: i01,
    note: "Develop 20+ innovative startups annually, enabling investor-driven innovation and timely exits within 540 days of formation.",
  },
  {
    svg: i02,
    note: "Launch 5+ co-owned startups per year, backed by scaleup investors, for public offering within 1080 days of Series A event, stimulating employment.",
  },
  {
    svg: i03,
    note: "Support 100+ entrepreneurs and entrepreneurial investors yearly, fostering entrepreneurship through the cADRE manufacturing franchise program.",
  },
  {
    svg: i04,
    note: "Establish 10+ mini startup-estates annually in rural areas, promoting rural development by collaborating with mentoring investors and associates.",
  },
];

function Objectives(props) {
  return (
    <div className="container bg containerh">
      <Title title="Our Objectives" />
      <div className="flex mfc manrope objs">
        {objectives.map((o, idx) => (
          <div className="gli align-center flex" key={idx}>
            <div className="g_ob flex justify-center align-center">
              <div
                style={{ backgroundImage: `url(${o.svg})` }}
                className="img_ob"
              ></div>
            </div>
            <p>{o.note}</p>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Objectives;
