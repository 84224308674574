import "../css/what.css";
import Title from "./Title";
import building from "../assets/building.png";
import vr from "../assets/vr.png";
import Check from "./Check";

const items = [
  "Accelerate the development and commercialization of y(our) innovations.",
  "Streamline the incorporation and scaling processes of startups for profitable exits.",
  "Access opportunities to participate in rewarding franchise ventures.",
  "Receive comprehensive mentoring to help you navigate the startup ecosystem with valuable guidance and support.",
];

function What() {
  return (
    <div id="what?" className="container bg what_cnt flex mfc justify-between">
      <div className="pm">
        <Title subtitle="csPILLAI" title="csPILLAI® Solves Key Challenges:" />
        <p className="manrope">
          <ol style={{ marginBottom: "5px" }}>
            <li>
              Lack of a comprehensive solution for industrializing the startup
              building and exiting process.
            </li>
            <li>
              Absence of guidance for novice investors on business investments.
            </li>
            <li>
              Inadequate mentorship for aspiring founders from idea conception
              to successful operations.
            </li>
            <li>
              Insufficient commercial support for self-employed inventors
              contributing to the global economy.
            </li>
          </ol>
          <br />
          To address these challenges, csPILLAI® employs the Broad Venture
          Builder (BVB) business model, a business model innovation in financial
          services that creates alternative investment opportunities through
          strategic venture building. This approach provides a holistic solution
          by industrializing the startup lifecycle, guiding novice investors
          through a step-by-step journey, offering mentorship for aspiring
          founders, and promoting support for self-employed inventors. This
          business model innovation drives startup investments, scaleup
          investments, franchise investments, and mentoring investments.
        </p>

        <div
          style={{ backgroundImage: `url(${building})` }}
          className="bg_img"
        ></div>
      </div>
      <div className="pm">
        <Title subtitle="csPILLAI" title="Benefits" />
        <p className="manrope">
          By choosing us as your Investment Platform, you gain several
          advantages for the businesses you invest in:
        </p>

        <ul className="ulw manrope">
          {items.map((i, idx) => (
            <li key={idx}>
              <Check />
              {i}
            </li>
          ))}
        </ul>
        <div
          style={{ backgroundImage: `url(${vr})` }}
          className="vr_cnt desktop"
        ></div>
      </div>
    </div>
  );
}

export default What;
