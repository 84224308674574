import * as React from "react";

function FormImagePicker({ name, onChange = () => {} }) {
  const [selectedImage, setSelectedImage] = React.useState(null);
  const selectImage = (e) => {
    setSelectedImage(e.target.files[0]);
    if (typeof onChange === "function") {
      onChange(e.target.files[0]);
    }
  };
  return (
    <div className={`img_cont ${selectedImage ? "focus" : ""}`}>
      <label htmlFor={name}>
        <div className="img_picker column flex justify-center align-center">
          {selectedImage ? (
            <svg
              width="32"
              height="32"
              viewBox="0 0 32 32"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="16" cy="16" r="16" fill="#0E79B7" />
              <circle cx="15.75" cy="10.25" r="5.75" fill="white" />
              <ellipse cx="16" cy="23" rx="9.5" ry="5" fill="white" />
              <path
                d="M15.75 4.5C14.225 4.5 12.7625 5.1058 11.6841 6.18414C10.6058 7.26247 10 8.725 10 10.25C10 11.775 10.6058 13.2375 11.6841 14.3159C12.7625 15.3942 14.225 16 15.75 16L15.75 10.25V4.5Z"
                fill="#CFE4F1"
              />
              <path
                d="M16 28C13.4804 28 11.0641 27.4732 9.28249 26.5355C7.50089 25.5979 6.5 24.3261 6.5 23C6.5 21.6739 7.50089 20.4021 9.28249 19.4645C11.0641 18.5268 13.4804 18 16 18V23V28Z"
                fill="#CFE4F1"
              />
            </svg>
          ) : (
            <svg
              width="29"
              height="29"
              viewBox="0 0 29 29"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M21.9993 25.6667H3.33268V7H15.3327V4.33333H3.33268C1.86602 4.33333 0.666016 5.53333 0.666016 7V25.6667C0.666016 27.1333 1.86602 28.3333 3.33268 28.3333H21.9993C23.466 28.3333 24.666 27.1333 24.666 25.6667V13.6667H21.9993V25.6667ZM11.6127 21.44L8.99935 18.2933L5.33268 23H19.9993L15.2793 16.72L11.6127 21.44ZM24.666 4.33333V0.333334H21.9993V4.33333H17.9993C18.0127 4.34667 17.9993 7 17.9993 7H21.9993V10.9867C22.0127 11 24.666 10.9867 24.666 10.9867V7H28.666V4.33333H24.666Z"
                fill="#BBBFC7"
              />
            </svg>
          )}

          <h4 style={{ fontSize: 12 }} className="font-semi-bold text-primary">
            {selectedImage
              ? "Image Uploaded Successfully"
              : "Upload Operator’s Picture"}
          </h4>
          {!selectedImage && (
            <span style={{ fontSize: 12 }} className="font-regular ch2">
              PNG, JPG up to 10MB
            </span>
          )}
        </div>
      </label>
      <input
        accept="image/*"
        onChange={selectImage}
        type="file"
        name={name}
        id={name}
      />
    </div>
  );
}

export default FormImagePicker;
