import { Route, Routes } from "react-router-dom";
import SideBar from "../../components/dashboard/SideBar";
import C404 from "../../pages/404";
import Dashboard from "../../pages/interships/dashboard/Dashboard";
import "../../css/dashboard/dashboard.css";
import AccountInfo from "../../components/dashboard/AccountInfo";
import Header from "../../components/dashboard/Header";
import { links3 } from "../../utils/routesLinks";
import SignAgreement from "../../pages/interships/dashboard/SignAgreement";
import Logs from "../../pages/interships/dashboard/Logs";
import Profile from "../../pages/interships/dashboard/Profile";
import Reports from "../../pages/interships/dashboard/Reports";
import Support from "../../pages/interships/dashboard/Support";
import Notifications from "../../pages/interships/dashboard/Notifications";
import ViewReport from "../../pages/interships/dashboard/ViewReport";
import Certificate from "../../pages/interships/dashboard/Certificate";

function DashboardInTern(props) {
  return (
    <div className="flex dhpa">
      <SideBar />
      <div className="routes_dash">
        <Header />
        <div className=" manrope container droutes ">
          <Routes>
            <Route path="/" element={<Dashboard />} />
            <Route path="/logs" element={<Logs />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/reports" element={<Reports />} />
            <Route path="/reports/:date" element={<ViewReport />} />
            <Route path="/support" element={<Support />} />
            <Route path="/notifications" element={<Notifications />} />
            <Route path="/certificate" element={<Certificate />} />
            <Route path={links3[1].from} element={<SignAgreement />} />
            <Route path="*" element={<C404 />} />
          </Routes>
        </div>
      </div>
      <AccountInfo />
    </div>
  );
}

export default DashboardInTern;
