import "../css/internhero.css";
import students from "../assets/students.png";
import { Link } from "react-router-dom";
// import RenderLogo from "./RenderLogo";

const RenderBtns = ({ className = "" }) => {
  return (
    <div className="flex btns_inh">
      <div
        onClick={() => {
          document
            .getElementById("formSign")
            .scrollIntoView({ behavior: "smooth" });
        }}
      >
        Get Started
      </div>
      <div>
        <Link to="/internship/login">Login</Link>
      </div>
    </div>
  );
};

function InternHero(props) {
  return (
    <>
      <div style={{ height: 60 }}></div>
      <div className="container ubuntu containerh internhero bg">
        <div className="flex ada justify-between mfc">
          <div className="txs_ih">
            <span>csPILLAI Internship</span>
            <h2>
              Make the most of <br className="mobile" /> your{" "}
              <span className="csp">internship</span> experience with us
            </h2>
          </div>
          <div className="picture img">
            <img src={students} alt="" />
            <div className="grd"></div>
          </div>
        </div>
        <RenderBtns className="mobile" />
      </div>
    </>
  );
}

export default InternHero;
