import FormInput from "../form/FormInput";
import FormSelect from "../form/FormSelect";
import { useFormikContext } from "formik";
import { useEffect } from "react";
import { useState } from "react";

function SelectCollege(props) {
  const [isOther, setIsOther] = useState(false);
  const { values, setFieldValue } = useFormikContext();

  useEffect(() => {
    if (values["college"] === "Other") {
      setFieldValue("college", "");
      setIsOther(true);
    } else if (
      [
        "Indian Institute of Technology, Bombay",
        "Indian Institute of Management, Bangalore",
      ].includes(values["college"])
    ) {
      setIsOther(false);
    }
  }, [values]);

  return (
    <>
      <FormSelect
        options={[
          "Indian Institute of Technology, Bombay",
          "Indian Institute of Management, Bangalore",
          "Other",
        ].map((s) => ({
          value: s,
          label: s,
        }))}
        label="College"
        placeholder="College"
        name="college"
      />
      {isOther && (
        <FormInput label="College" placeholder="College" name="college" />
      )}
    </>
  );
}

export default SelectCollege;
