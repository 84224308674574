import { useContext } from "react";
import InternContext from "../../contexts/InternContext";
// import "../../css/dashbaord/header.css";
import "../../css/dashboard/header.css";

function Header(props) {
  const { user, routeTitle } = useContext(InternContext);
  return (
    <div className="container dheader containerh manrope">
      <h2>{routeTitle}</h2>
      <p>Hello, {user.firstName}. Welcome to csPILLAI </p>
    </div>
  );
}

export default Header;
