import { useEffect } from "react";
import { useState } from "react";
import Button from "../Button";
import Input from "../Input";
import Alert from "./Alert";

function AddDuties({ updateDuties }) {
  const [duty, setDuty] = useState("");
  const [duties, setDuties] = useState([]);

  const add = () => {
    if (!duty) return;
    setDuties([duty, ...duties]);
  };

  useEffect(() => {
    updateDuties(duties);
  }, [duties]);

  return (
    <div>
      <div className="flex duty_cnt">
        <Input
          value={duty}
          onChange={setDuty}
          label="Add duty"
          placeholder="Duty/Responsibility"
        />
        <Button onClick={add} title="Add" />
      </div>
      {duties.map((d, idx) => (
        <Alert
          closeItem={(e) => setDuties(duties.filter((i, d) => d !== idx))}
          key={idx}
          type="success"
          message={d}
        />
      ))}
    </div>
  );
}

export default AddDuties;
