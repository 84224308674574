import "../../css/dashboard/table.css";
import Row from "./Row";

function Table({ head = [], data = [] }) {
  return (
    <div className="table">
      <Row head items={head} />
      {data.map((d, idx) => (
        <Row
          key={idx}
          items={head.map((h) => ({
            ...d,
            title: d[h.target],
            className: h.className,
            render: h.render,
          }))}
        />
      ))}
    </div>
  );
}

export default Table;
