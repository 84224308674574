import React from "react";
import { useContext } from "react";
import RenderImageProfile from "../../../components/dashboard/RenderImageProfile";
import InternContext from "../../../contexts/InternContext";
import Form from "../../../form/Form";
import FormInput from "../../../form/FormInput";
import * as Yup from "yup";
import useClient from "../../../hooks/useClient";
import urls from "../../../api/urls";
import Submit from "../../../form/Submit";
import FilePicker from "../../../components/FilePicker";

const inps = [
  {
    title: "First Name",
    getData: (u) => u.firstName,
  },
  {
    title: "Last Name",
    getData: (u) => u.lastName,
  },
  {
    title: "Email",
    getData: (u) => u.email,
  },
  {
    title: "Phone Number",
    getData: (u) => u.contact.phoneNumber,
  },
  {
    title: "College",
    getData: (u, a) => u.college,
  },
  {
    title: "Position",
    getData: (u, a) => a?.position,
  },
  {
    title: "Department",
    getData: (u, a) => a?.department,
  },
  {
    title: "Working days",
    getData: (u, a) => `${a?.workDays?.from}, ${a?.workDays?.to}`,
  },
];

const formInitialData = {
  password: "",
  newPassword: "",
};

const validationSchema = Yup.object().shape({
  password: Yup.string().required().label("Current Password"),
  newPassword: Yup.string().required().label("New Password"),
});

function Profile(props) {
  const { user, setUser, agreement } = useContext(InternContext);
  const { patch, isLoading } = useClient();

  const handleSubmit = async (fdata) => {
    const { data, error } = await patch(urls.interns.changePassword, fdata);
    if (error) return alert(error.message);
    alert(data.message);
  };

  return (
    <div className="chart_cnt cnpa">
      <h2>Personal Details</h2>
      <RenderImageProfile />
      <div className="inops">
        {inps.map(({ title, getData }, idx) => (
          <div key={idx}>
            <span>{title}</span>
            <span>{getData(user, agreement)}</span>
          </div>
        ))}
      </div>
      <FilePicker
        fname="resume"
        rtype="patch"
        onUpload={(resume) => setUser({ ...user, resume })}
        uploadUrl={urls.interns.chnageResume}
        placeholder="Resume"
        name="resume"
      />
      <FilePicker
        fname="profileImage"
        rtype="patch"
        onUpload={(profileImage) => setUser({ ...user, profileImage })}
        uploadUrl={urls.interns.changeProfileImage}
        placeholder="Profile Image"
        name="profileImage"
      />
      <br />
      <h2>Change Password</h2>
      <Form
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
        initialValues={formInitialData}
      >
        <div className="inputs">
          <FormInput
            name="password"
            label="Current Password"
            placeholder="Password"
            isPassword
          />
          <FormInput
            name="newPassword"
            label="New Password"
            placeholder="Password"
            isPassword
          />
        </div>
        <Submit loading={isLoading} className="gradient" title="Save" />
      </Form>
    </div>
  );
}

export default Profile;
