function TermsOfUse(props) {
  return (
    <div className="lcontent">
      <p>Last updated: [23.08.23]</p>
      <p>
        Please read these Terms of Service ("terms") carefully before using the
        'Online Investment Ecosystem' website at www.cspillai.com ("us," "we,"
        or "our").
      </p>

      <h2>1. Acceptance of terms</h2>
      <p>
        You agree to abide by these terms and all relevant laws and regulations
        by accessing or using our website. If you do not agree with these terms,
        you may not use our website.
      </p>

      <h2>2. Eligibility</h2>
      <p>
        To use our website, you must be at least 18 years old and able to form a
        legally binding contract. By using our website, you represent and
        warrant that you meet these eligibility requirements.
      </p>

      <h2>3. User Responsibilities</h2>
      <p>
        You are responsible for any actions you take on our website and must
        comply with these terms and all applicable laws. You agree not to:
      </p>
      <ol>
        <li>Use our website in any unlawful or unauthorized manner</li>
        <li>Impersonate any person or entity</li>
        <li>Interfere with or disrupt the functionality of our website</li>
        <li>Share or disclose your account credentials to any third party</li>
        <li>
          Engage in any fraudulent activity or unauthorized use of other users'
          accounts
        </li>
        <li>Upload or transmit any malicious code or viruses</li>
        <li>Violate the privacy or intellectual property rights of others</li>
      </ol>

      <h2>4. Intellectual property rights</h2>
      <p>
        Our website and its content, including but not limited to designs,
        texts, graphics, images, logos, and software, are owned or licensed by
        us and protected by intellectual property laws. You may not reproduce,
        distribute, modify, or create derivative works of any part of our
        website without our prior written consent.
      </p>

      <h2>5. Investment Risks</h2>
      <p>
        We provide information and resources related to investments but do not
        provide financial advice. Any investment decisions you make based on the
        information available on our website or through our services are at your
        own risk. We are not responsible for any financial losses you may incur.
      </p>

      <h2>6. Third-Party Content and Links</h2>
      <p>
        Our website may contain links to third-party websites and content. We do
        not endorse or control these third-party websites, and we are not
        responsible for their content or accuracy. Your interactions with these
        third parties are at your own risk.
      </p>

      <h2>7. Disclaimer of Warranties</h2>
      <p>
        Your use of our website and services is at your sole risk. We provide
        our website on an "as is" and "as available" basis. We do not warrant
        that our website will be error-free, uninterrupted, or secure. We
        disclaim all warranties, whether express, implied, or statutory,
        including but not limited to warranties of accuracy, fitness for a
        particular purpose, and non-infringement.
      </p>

      <h2>8. Limitation of Liability</h2>
      <p>
        To the extent permitted by law, we shall not be liable for any indirect,
        incidental, special, or consequential damages arising out of or in
        connection with your use of our website, even if we have been advised of
        the possibility of such damages. Our total liability for any claim
        arising under these terms will not exceed the amount you paid, if any,
        to use our website.
      </p>

      <h2>9. Termination</h2>
      <p>
        We reserve the right to terminate or suspend your access to our website
        at any time, without prior notice or liability, for any reason,
        including but not limited to a breach of these terms.
      </p>

      <h2>10. Governing Law</h2>
      <p>
        The laws of Maharashtra, Mumbai, India, shall govern these terms and
        guide their interpretation. Any disputes arising out of or relating to
        these terms shall be resolved exclusively in the courts of Navi Mumbai.
      </p>

      <h2>11. Modifications to Terms</h2>
      <p>
        We may update or modify these terms from time to time. Any changes will
        be effective immediately upon posting on our website. Your continued use
        of our website after the changes will signify your acceptance of the
        modified terms.
      </p>

      <h2>12. Contact Us</h2>
      <p>
        If you have any questions or concerns about these terms, please contact
        us at {"  "}
        <a href="mailto:mail@cspillai.com">mail@cspillai.com</a>.
      </p>
      <br />

      <p>
        By using our website, you agree to abide by these terms and any future
        modifications or updates.
      </p>
    </div>
  );
}

export default TermsOfUse;
