import { useContext } from "react";
import profile from "../../assets/profile.png";
import InternContext from "../../contexts/InternContext";

function RenderImageProfile({ className = "", imageOnly = false }) {
  const { user } = useContext(InternContext);

  const RenderImage = () => (
    <img
      src={
        user.profileImage
          ? `http://172.20.10.3:8090/api/v1/file-streams/${user.profileImage}`
          : profile
      }
      alt=""
    />
  );

  if (imageOnly) return <RenderImage />;

  return (
    <div className={`profileImage ${className}`}>
      <div className="flex justify-center align-center">
        <RenderImage />
      </div>
    </div>
  );
}

export default RenderImageProfile;
