import { useEffect } from "react";
import { useRef } from "react";
import { useState } from "react";
import "../css/filepicker.css";
import useClient from "../hooks/useClient";
import Loader from "./Loader";

function FilePicker({
  name,
  accept = "*/*",
  onChange = () => {},
  style = {},
  uploadUrl,
  fname,
  onUpload,
  placeholder,
  t = "File",
  rtype = "post",
}) {
  const { isLoading, post, patch } = useClient();
  const [selectedFile, setSelectedFile] = useState(null);
  const [isDragging, setIsDragging] = useState(false);
  const imgRef = useRef(null);
  const selectFile = (e) => {
    setSelectedFile(e.target.files[0]);
    onChange(e.target.files[0]);
  };
  function dropHandler(ev) {
    setIsDragging(false);
    ev.preventDefault();
    if (ev.dataTransfer.items) {
      if (ev.dataTransfer.items[0].kind === "file") {
        var file = ev.dataTransfer.items[0].getAsFile();
        selectFile({ target: { files: [file] } });
      }
    } else {
      selectFile({ target: { files: [ev.dataTransfer.files[0]] } });
    }
  }
  function dragOverHandler(ev) {
    ev.preventDefault();
    if (!isDragging) setIsDragging(true);
  }

  useEffect(() => {
    if (selectedFile) {
      const reader = new FileReader();
      reader.addEventListener("load", (ev) => {
        imgRef.current.setAttribute("src", ev.target.result);
      });
      reader.readAsDataURL(selectedFile);
    }
  }, [selectedFile]);

  const uploadImage = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append(fname, selectedFile);
    const request = rtype === "post" ? post : patch;
    const response = await request(uploadUrl, formData, {
      headers: { "Content-Type": "multipart/form-data" },
    });
    setSelectedFile(null);
    if (!response.error)
      return onUpload(response.data.data.key || response.data.data.url);
    alert("Unable to upload image");
  };
  return (
    <label htmlFor={name}>
      <div
        onDrop={dropHandler}
        onDragOver={dragOverHandler}
        onDragLeave={() => setIsDragging(false)}
        className="picker manrope flex justify-center align-center"
      >
        {isLoading ? (
          <Loader loading />
        ) : isDragging ? (
          <span>Drop here...</span>
        ) : selectedFile ? (
          <div className="preview flex">
            <img ref={imgRef} alt={name} />
            {uploadUrl && (
              <button className="btn ubtn_upload" onClick={uploadImage}>
                Upload
              </button>
            )}
          </div>
        ) : (
          <>
            <div className="svg_li flex justify-center align-center">
              <svg
                width="51"
                height="44"
                viewBox="0 0 51 44"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M24.5 28C24.5 28.8284 25.1716 29.5 26 29.5C26.8284 29.5 27.5 28.8284 27.5 28H24.5ZM27.0607 0.939341C26.4749 0.353554 25.5251 0.353554 24.9393 0.939341L15.3934 10.4853C14.8076 11.0711 14.8076 12.0208 15.3934 12.6066C15.9792 13.1924 16.9289 13.1924 17.5147 12.6066L26 4.12132L34.4853 12.6066C35.0711 13.1924 36.0208 13.1924 36.6066 12.6066C37.1924 12.0208 37.1924 11.0711 36.6066 10.4853L27.0607 0.939341ZM27.5 28V2H24.5V28H27.5Z"
                  fill="#8D8D8D"
                />
                <path
                  d="M2 15V39C2 40.6569 3.34315 42 5 42H46C47.6569 42 49 40.6569 49 39V15"
                  stroke="#8D8D8D"
                  strokeWidth="3"
                  strokeLinecap="round"
                />
              </svg>
            </div>
            <span>Drag and Drop {placeholder}</span>
            <span>Or</span>
            <span>Select {t}</span>
          </>
        )}

        <input accept={accept} onChange={selectFile} type="file" id={name} />
      </div>
    </label>
  );
}

export default FilePicker;
