function Check(props) {
  return (
    <div {...props}>
      <svg
        width="32"
        height="33"
        viewBox="0 0 32 33"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 16.1258C0 11.849 1.68571 7.74733 4.68629 4.72315C7.68687 1.69897 11.7565 0 16 0C20.2435 0 24.3131 1.69897 27.3137 4.72315C30.3143 7.74733 32 11.849 32 16.1258C32 20.4027 30.3143 24.5043 27.3137 27.5285C24.3131 30.5527 20.2435 32.2517 16 32.2517C11.7565 32.2517 7.68687 30.5527 4.68629 27.5285C1.68571 24.5043 0 20.4027 0 16.1258H0ZM15.0869 23.0277L24.2987 11.4214L22.6347 10.0797L14.7797 19.9724L9.216 15.3002L7.85067 16.9515L15.0869 23.0299V23.0277Z"
          fill="#FF5043"
        />
      </svg>
    </div>
  );
}

export default Check;
