import Title from "./Title";
import i01 from "../assets/sponsor/01.png";
import i02 from "../assets/sponsor/02.png";
import Check from "./Check";

const objs = [
  "Revenue and goodwill.",
  "Tax revenue",
  "Provide  employment",
  "positively impact the upcoming generation’s entrepreneurial mindset",
  "Good economic conditions",
  "Standard quality of life",
  "Good working conditions.",
];

function Sp01(props) {
  return (
    <div className="container containerh bg manrope">
      <div className="flex mfc">
        <div className="psTx">
          <Title title="The global entrepreneurship space." />
          <p>
            The global entrepreneurship space has continued to expand over the
            years as more people, including intrapreneurs within csPILLAI, are
            inventing one or other solutions continually in solitude to meet the
            needs and problems of millions of people in different parts of the
            world. Women have become significant players in the entrepreneurial
            world, particularly in the tech domain, with several female-driven
            firms emerging recently. According to a current stat, women own 36%
            of small businesses worldwide, with 47% of women starting a business
            in the last year compared to 44% of men. Despite the fantastic
            figures regarding women’s entrepreneurship, more needs to be done to
            involve them in rural development. The company has chalked out a
            plan to tackle and realize growth in rural areas using woman
            entrepreneurs. Hence, csPILLAI has taken the bull by the horns,
            invented a unique business service( cADRE or invest2enterprise
            service), and craves to accomplish rural development through woman
            entrepreneurs.
          </p>
        </div>
        <div className="img01 flex justify-center align-center">
          <img src={i01} alt="" />
        </div>
      </div>
      <br />
      <p>
        The team at csPILLAI has reemphasized their commitment to building
        entrepreneurships and people simultaneously through the cADRE business.
        csPILLAI’s legal associate, Literati Juris, designed the business
        legalities for cADRE, and cADRE stands for csPILLAI Advanced Doorstep
        Rural Entrepreneurship. cADRE is modeled and molded as a unique
        Entrepreneurship Business: Franchise Entrepreneurship Business-1.50 to
        promote women’s entrepreneurship and rural development.
      </p>
      <br />
      <div className="flex jkla">
        <div className="img02 flex justify-center align-center">
          <img src={i02} alt="" />
        </div>
        <div className="txsp">
          <Title title="Benefits" />
          <ul className="ulw manrope">
            {objs.map((i, idx) => (
              <li key={idx}>
                <Check />
                {i}
              </li>
            ))}
          </ul>
          <p>
            csPILLAI has been described by many as the Broad Venture
            Builder[BVB], a business & startup investing online platform,
            professionally building ‘Startups for low gain short-term exits’ and
            ‘Scaleups for high gain long-term exits.’ The company aims to
            translate its wealth of experience to encourage women entrepreneurs
            and other stakeholders in rural areas to take part in rural
            development and rural wellbeing. The company will address problems
            in rural areas and women entrepreneurship by offering strait-laced
            support through its Associates. csPILLAI says, “we will dedicate
            most of the profits to the initiatives that can improve human life.”
          </p>
        </div>
      </div>
    </div>
  );
}

export default Sp01;
