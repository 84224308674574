import Title from "./Title";
// import svg from "../assets/svg/ideology.svg";
import img from "../assets/ideology.png";
import "../css/ideology.css";
import vr from "../assets/vr.png";

function Ideology(props) {
  return (
    <div id="why?" className="container containerh bg">
      <Title title="Our Ideology" />
      <div className="flex manrope mfc flex  idl">
        <div className="imgIdl flex  justify-center align-center">
          <img src={img} alt="csPillai Ideology" />
        </div>
        <div className="tid">
          {/* <p>BVB is csPILLAI.</p> */}
          <p>
            The core ideology of the Investment Ecosystem Powered by Broad
            Venture Builder (BVB) business model is rooted in Humanism. We
            believe in "making money and doing good at the same time" by using
            innovation and entrepreneurship to drive employment, rural
            development, and overall welfare and wellbeing. Through our BVB
            Business Model, we aim to create sustainable growth and empower
            individuals and communities for a better future.
          </p>
          <div className="flex desktop justify-center align-center">
            <img src={vr} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Ideology;
