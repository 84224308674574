const getItem = (key) => localStorage.getItem(key) || null;
const setItem = (key, value) => {
  // if (!getItem("isAccepted")) return;
  localStorage.setItem(key, value);
};
const removeItem = (key) => localStorage.removeItem(key);

const accessTokenKey = "accessToken";
const refreshTokenKey = "refreshToken";

const getAccessToken = () => getItem(accessTokenKey);
const getRefreshToken = () => getItem(refreshTokenKey);
const setAccessToken = (token) => setItem(accessTokenKey, token);
const setRefreshToken = (token) => setItem(refreshTokenKey, token);
const removeToken = () => {
  removeItem(accessTokenKey);
  removeItem(refreshTokenKey);
};
const setTokens = (tokens) => {
  setAccessToken(tokens.accessToken);
  setRefreshToken(tokens.refreshToken);
};

const store = {
  getItem,
  setItem,
  removeItem,
  getAccessToken,
  getRefreshToken,
  setAccessToken,
  setRefreshToken,
  removeToken,
  setTokens,
};
export default store;
