function PrivacyPolicy(props) {
  return (
    <div className="lcontent">
      <p>Last updated: [23.08.23]</p>
      <p>
        This Privacy Policy describes how we, the 'Online Investment Ecosystem'
        website at www.cspillai.com ("we," "us," or "our"), collect, use, and
        disclose your personal information when you visit our website and
        utilize our services.
      </p>

      <h2>1. Information Collection and Use</h2>
      <p>
        We may collect personal information from you when you voluntarily
        provide it, such as when you register for an account, submit
        investment-related information, or contact us for support. The types of
        personal information we collect may include, but are not limited to:
      </p>
      <ol>
        <li>Name</li>
        <li>Contact information (email address, phone number)</li>
        <li>Financial and investment information Demographic information</li>
        <li>User preferences and settings</li>
        <li>
          Log and device information (IP address, browser type, operating
          system)
        </li>
      </ol>

      <h2>2. Use of Collected Information</h2>
      <p>We collect information about you in the following ways:</p>
      <ol>
        <li>To provide and improve our services to you</li>
        <li>
          To communicate with you, respond to your inquiries, and provide
          customer support
        </li>
        <li>To personalize your experience on our website</li>
        <li>To send you important notices or information about our services</li>
        <li>To analyze and monitor website usage</li>
        <li>To prevent and address technical issues or security breaches</li>
        <li>To comply with legal obligations</li>
      </ol>

      <h2>3. Data Sharing and Disclosures</h2>
      <p>
        We do not sell, trade, or rent your personal information to third
        parties for marketing purposes. However, we may share your information
        in the following circumstances:
      </p>
      <ol>
        <li>
          With service providers who assist us in providing and improving our
          services (e.g., hosting providers, payment processors)
        </li>
        <li>
          With our professional advisors (e.g., lawyers, accountants) for legal,
          accounting, or compliance purposes
        </li>
        <li>
          With law enforcement if required by applicable law or in response to a
          valid court order
        </li>
        <li>
          In connection with a business transaction (e.g., merger, acquisition)
          where personal information may be transferred as part of the assets
        </li>
      </ol>

      <h2>4. Data Security:</h2>
      <p>
        We implement reasonable measures to protect your personal information
        and maintain its confidentiality. However, no method of transmission or
        storage is entirely secure, and we cannot guarantee absolute security.
      </p>

      <h2>5. Cookies and tracking technologies</h2>
      <p>
        We may use cookies and similar tracking technologies to collect and
        store certain information when you visit our website. This helps us
        enhance your user experience and provide customized content. You can
        manage your cookie preferences by adjusting your browser settings.
      </p>

      <h2>6. Third-Party Links</h2>
      <p>
        Our website may contain links to third-party websites or services. We
        are not responsible for the privacy practices or content of those
        websites. We encourage you to review the privacy policies of any
        third-party website before providing your personal information.
      </p>

      <h2>7. Children's Privacy</h2>
      <p>
        Our services are not intended for individuals under the age of 18. We do
        not knowingly collect personal information from children. If you believe
        we may have inadvertently collected personal information from a child,
        please contact us to request its deletion.
      </p>

      <h2>8. Changes to this Privacy Policy</h2>
      <p>
        We may update this Privacy Policy from time to time. Any changes or
        modifications will be effective immediately upon posting on our website.
        We recommend reviewing this Privacy Policy periodically to stay informed
        about our information practices.
      </p>

      <h2>9. Contact us</h2>
      <p>
        If you have any questions or concerns about this Privacy Policy or our
        information practices, please contact us at [mail@cspillai.com].
      </p>

      <br />
      <br />
      <p>
        By using our website and services, you agree to the processing of your
        personal information as described in this Privacy Policy.
      </p>
    </div>
  );
}

export default PrivacyPolicy;
