function Copyright(props) {
  return (
    <div className="lcontent">
      <p>
        All content, information, and materials available on the website
        www.cspillai.com, including but not limited to text, graphics, logos,
        images, videos, audio clips, data compilations, and software, are the
        property of csPILLAI™, and are protected by copyright laws.
      </p>
      <br />

      <p>
        Any unauthorized use, reproduction, distribution, modification, or
        transmission of the copyrighted materials on this website is strictly
        prohibited. You may not copy, reproduce, republish, upload, post,
        display, transmit, or distribute any part of the website's content
        without prior written permission from csPILLAI™.
      </p>
      <br />

      <p>
        The Online Investment Ecosystem provided on www.cspillai.com is
        protected by copyright as a collective work and/or compilation, pursuant
        to applicable copyright laws. You must not use the Online Investment
        Ecosystem for any unauthorized commercial purposes or modify, adapt,
        create derivative works, or reverse engineer any portion of the Online
        Investment Ecosystem.
      </p>
      <br />

      <p>
        csPILLAI™ reserves all rights not expressly granted in this Copyright
        Notice. Unauthorized use of any materials available on www.cspillai.com
        may violate intellectual property rights, copyright laws, and other
        applicable statutes. csPILLAI™ will take legal action against any
        unauthorized use or infringement of its intellectual property.
      </p>
      <br />

      <p>
        By accessing and using the website www.cspillai.com, you acknowledge and
        agree to abide by this Copyright Notice.
      </p>
      <br />
    </div>
  );
}

export default Copyright;
