import Title from "./Title";
import aboutImg from "../assets/about.png";
import "../css/about.css";
import founder from "../assets/founder.png";
import Check from "./Check";

const items = [
  "Startup Investing",
  "Private Equity",
  "Venture Capital",
  "Real Estate",
  "Franchising",
  "Commodities",
];

function About() {
  return (
    <div className="container about">
      <Title title="About Us" />
      <div className="flex mfc abt">
        <div
          style={{ backgroundImage: `url(${aboutImg})` }}
          className="img_abt"
        ></div>
        <div className="infs">
          <div className="tnxs">
            <Title title="Aim" />
            <p className="manrope">
              Our aim is to:
              <ol>
                <li>
                  <span style={{ fontWeight: 600 }}>
                    Support Angel Investors:
                  </span>{" "}
                  Provide comprehensive support and step-by-step guidance to new
                  angel investors, ensuring a smooth investment process in
                  csPILLAI® startups.
                </li>
                <li>
                  <span style={{ fontWeight: 600 }}>Foster Innovation:</span>{" "}
                  Encourage young entrepreneurs to join csPILLAI® as
                  manufacturing partners, nurturing their passion for
                  innovation.
                </li>
                <li>
                  <span style={{ fontWeight: 600 }}>Attract Expertise:</span>{" "}
                  Engage accomplished founders and industry experts as
                  management and mentoring partners, driving the success of
                  csPILLAI® ventures.
                </li>
                <li>
                  <span style={{ fontWeight: 600 }}>Secure Funding:</span> Build
                  dynamic partnerships with Venture Capitalists (VCs) to secure
                  essential funds for the growth and expansion of csPILLAI®
                  scaleups, facilitating successful Mergers and Acquisitions
                  (M&As) or Initial Public Offerings (IPOs), maximizing value
                  for all stakeholders.
                </li>
              </ol>
            </p>
          </div>
          <div className="tnxs">
            <Title title="Purpose" />
            <p className="manrope">
              Our purpose is to:
              <ol>
                <li>
                  <span style={{ fontWeight: 600 }}>
                    Generate Profitable Returns:
                  </span>{" "}
                  Operate an Online Investment Ecosystem based on the Broad
                  Venture Builder business model, offering alternative
                  investment opportunities, making strategic decisions and
                  exits, and leveraging investments from our valued investors.
                </li>
                <li>
                  <span style={{ fontWeight: 600 }}>
                    Create Positive Impact:
                  </span>{" "}
                  Facilitate remote employment opportunities that contribute to
                  human well-being and promote fair and transparent competition,
                  free from deception or fraud.
                </li>
                <li>
                  <span style={{ fontWeight: 600 }}>
                    Align Financial Success with Responsibility:
                  </span>{" "}
                  Balance short-term gains with long-term, sustainable returns,
                  creating value for all stakeholders by aligning financial
                  success with social responsibility and sustainable practices.
                </li>
              </ol>
            </p>
          </div>
        </div>
      </div>
      <br id="about" />
      <Title title="Profile" />
      <div className="bvb manrope mfc flex">
        <div className="nts">
          <p>
            Welcome to csPILLAI®: Your Gateway to Innovative Alternative
            Investment Opportunities!
            <br />
            <br />
            At csPILLAI®, we redefine the investment landscape as Alternative
            Investment Facilitators. Our unique Broad Venture Builder (BVB)
            hybrid business model integrates various other business models such
            as venture building, platform services, auctions, collaborations,
            and franchising. This dynamic and multifaceted business model
            innovation sets us apart from conventional investment platforms
            <br />
            <br />
            Our ecosystem transcends traditional asset classes like stocks and
            bonds. Instead, we specialize in:
            <ul className="ulw">
              {items.map((i, idx) => (
                <li key={idx}>
                  <Check />
                  {i}
                </li>
              ))}
            </ul>
            csPILLAI® provides investors with access to a diversified portfolio,
            aiming for higher returns through strategic alternative investments.
            We leverage our extensive expertise and robust networks to uncover
            and capitalize on unique investment opportunities. Our platform
            fosters collaboration and knowledge sharing, bridging the gap
            between investors and industry experts.
            <br />
            <br />
            The Advantages of Alternative Investments with csPILLAI®:
            <ol className="">
              <li>
                <span style={{ fontWeight: 600 }}>
                  Enhanced Portfolio Diversification:
                </span>{" "}
                Broaden your investment horizons beyond traditional assets.
              </li>
              <li>
                <span style={{ fontWeight: 600 }}>Reduced Overall Risk:</span>{" "}
                Mitigate risks with a well-balanced investment approach.
              </li>
              <li>
                <span style={{ fontWeight: 600 }}>
                  Potential for Higher Returns:
                </span>{" "}
                Aim for superior returns through innovative investment
                strategies.
              </li>
            </ol>
            <br />
            Understanding and investing in alternative assets are becoming
            crucial for those looking to advance their investment careers.
            csPILLAI® is here to guide you through this journey with a
            commitment to sustainability and social responsibility.
            <br />
            <br />
            Join us at csPILLAI® and explore a world of alternative investment
            opportunities designed to meet your unique investment needs and
            goals.
          </p>
        </div>
        <div className="img">
          <div
            // style={{ backgroundImage: `url(${founder})` }}
            className="pimg"
          >
            <img src={founder} alt="" />
          </div>
          <Title
            subtitle="C.S. PILLAI (1940-1996)"
            title="The Real Visionary"
          />
        </div>
        <div className="nts">
          <p>
            The Broad Venture Builder (BVB) business model at the heart of
            csPILLAI® is dedicated to achieving multiple goals including:
            <br /> 1) innovation, <br /> 2) Entrepreneurship, <br /> 3)
            Employment, & <br /> 4) Rural development. <br /> <br />
            Through its range of services and products, csPILLAI® aims to drive
            positive change in these areas.
          </p>
          <p>
            The name csPILLAI® honors the visionary Mr. C.S. Pillai, a
            pioneering S.C. Railway loco pilot from Gooty R.S., India. The
            pronunciation of csPILLAI® is SEE-YES-PILL-EYE.
          </p>
          <p>
            At its core, csPILLAI® embraces the ideology of Humanism and strives
            to make a positive societal impact. The philosophy followed by
            csPILLAI® revolves around the concept of "making money and doing
            good at the same time" through value-driven innovations.
          </p>
          <p>
            By combining financial success with social responsibility, csPILLAI®
            aims to create a sustainable and impactful investment ecosystem that
            benefits individuals, communities, and the broader society.
          </p>
        </div>
      </div>
      <div style={{ width: "100%", paddingLeft: 0 }} className="infs mfc flex">
        <div className="tnxs">
          <Title title="Mission" />
          <p className="manrope">
            "To empower investors with cutting-edge AI, unlocking unparalleled
            opportunities in alternative investments, while prioritizing ethical
            practices and regulatory compliance."
          </p>
        </div>
        <div className="tnxs">
          <Title title="Vision" />
          <p className="manrope">
            "To be the leading global innovator in alternative investments,
            leveraging AI to transform investment landscapes, uphold ethical
            standards, and ensure sustainable success for all stakeholders."
          </p>
        </div>
      </div>
      {/* <img className="sl1" src={slice1} alt="" /> */}
    </div>
  );
}

export default About;
