import MarqueeI from "react-fast-marquee";
import col01 from "../assets/col/01.png";
import col02 from "../assets/col/02.png";
import img1 from "../assets/col/acheivement-1.png";
import img2 from "../assets/col/acheivement-2.png";
import img3 from "../assets/col/acheivement-3.png";

function Marquee(props) {
  return (
    <div className="marquee">
      <MarqueeI speed={30}>
        <div className="logos_col">
          <img src={img1} alt="" className="blur" />
          <img src={img3} alt="" className="blur" />
          <img src={img2} alt="" />
        </div>
      </MarqueeI>
    </div>
  );
}

export default Marquee;
