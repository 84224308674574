import Footer from "../components/Footer";
import Header from "../components/Header";
import InternBody from "../components/InternBody";
import InternHero from "../components/InternHero";
import Share from "../components/Share";
import SignupIntern from "../components/SignupIntern";

function Intern(props) {
  return (
    <>
      <Header />
      <InternHero />
      <InternBody />
      <SignupIntern />
      <Share />
      <Footer />
    </>
  );
}

export default Intern;
