import { Route, Routes } from "react-router-dom";
import C404 from "../../pages/404";
import Intern from "../../pages/Intern";
import Login from "../../pages/interships/auth/Login";
import RecoverAccoount from "../../pages/interships/auth/RecoverAccount";

function Auth(props) {
  return (
    <Routes>
      <Route path="/" element={<Intern />} />
      <Route path="/recover-account" element={<RecoverAccoount />} />
      {/* <Route path="/register" element={<Register />} /> */}
      <Route path="/login" element={<Login />} />
      <Route path="*" element={<C404 />} />
    </Routes>
  );
}

export default Auth;
