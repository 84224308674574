import { useRef } from "react";
import { useContext } from "react";
import s1 from "../../../assets/cert/s1.svg";
import s2 from "../../../assets/cert/s2.svg";
import logo from "../../../assets/svg/logo.svg";
import InternContext from "../../../contexts/InternContext";
import "../../../css/dashboard/cert.css";
import Pdf from "react-to-pdf";
import Button from "../../../components/Button";
import { useState } from "react";
import Loader from "../../../components/Loader";
import useClient from "../../../hooks/useClient";
import { useEffect } from "react";
import urls from "../../../api/urls";
import { months } from "../../../utils/dates";

function Certificate(props) {
  const [isLoading, setIsLoading] = useState(false);
  const { user } = useContext(InternContext);
  const [certificate, setCertificate] = useState(null);
  const ref = useRef(null);
  const { get, isLoading: getLoading } = useClient();

  const getCertificate = async () => {
    const { data, error } = await get(urls.interns.certificate.baseurl);
    if (error) return;
    setCertificate(data.data.certificate);
  };

  useEffect(() => {
    getCertificate();
  }, []);

  if (getLoading) return <Loader loading />;
  if (!certificate)
    return <h2>No certificate avilable for you at the moment</h2>;
  return (
    <div className="pplh poppins">
      <Pdf
        options={{
          orientation: "landscape",
          unit: "px",
          format: [928, 718],
        }}
        onComplete={() => {
          setIsLoading(false);
        }}
        targetRef={ref}
        filename={`${user.firstName.toLowerCase()}_${user.lastName.toLowerCase()}.pdf`}
      >
        {({ toPdf }) => (
          <Button
            style={{ marginBottom: 20 }}
            loading={isLoading}
            onClick={() => {
              setIsLoading(true);
              toPdf();
            }}
            className="gradient"
            title="Download PDF"
          />
        )}
      </Pdf>
      <div ref={ref} className="certificate_container">
        <div className="cnt_crt">
          <h2 className="rr">CERTIFICATE OF INTERNSHIP </h2>
          <div className="cert_cnt">
            <h4 className="rr">THIS IS PRESENTED TO</h4>
            <h1>
              {user.firstName} {user.lastName}
            </h1>
            <p>
              For completing his {certificate.hoursWorked}-hours internship
              program at csPILLAI (CS PILLAI VENTURES PRIVATE LIMITED)
            </p>
            <p>{certificate.comment}</p>
            <h5>
              {months[new Date(certificate.createdAt).getMonth()]},{" "}
              {new Date(certificate.createdAt).getFullYear()}
            </h5>
          </div>
          <div className="scfooter flex">
            <img src={s1} alt="" />
            <img src={s2} alt="" />
          </div>
        </div>
        <div className="logo_cert">
          <img src={logo} alt="" />
        </div>
        {/* <img src={cir} alt="" className="" /> */}
      </div>
    </div>
  );
}

export default Certificate;
