import * as React from "react";
import { Line } from "react-chartjs-2";
import { days, getHoursWorked } from "../../utils/dates";

const getData = (activities) =>
  days
    .filter((d) => d !== "Sunday" && d !== "Saturday")
    .map((d) =>
      getHoursWorked(
        activities.find((a) => days[new Date(a.createdAt).getDay()] === d)
      )
    );

function Activity({ activities }) {
  return (
    <div className="chart_cnt">
      {/* <Input
        select
        options={[
          {
            label: "This week",
          },
          {
            label: "All weeks",
          },
        ]}
        placeholder="Select graph"
      /> */}
      <br />
      <div className="">
        <Line
          style={{ height: 420, width: 100 }}
          options={{
            maintainAspectRatio: false,
            //   legend: {
            //     display: false,
            //   },
          }}
          data={{
            labels: days.filter((d) => d !== "Sunday" && d !== "Saturday"),
            datasets: [
              {
                label: "This week",
                data: getData(activities),
                // data: [
                //   getHoursWorked(activities[0]),
                //   getHoursWorked(activities[1]),
                //   getHoursWorked(activities[2]),
                //   getHoursWorked(activities[3]),
                //   getHoursWorked(activities[4]),
                // ],
                fill: true,
                borderColor: "rgba(255, 80, 67, 0.4)",
                tension: 0.3,
                borderWidth: 2,
                backgroundColor: "rgba(255, 80, 67, 0.05)",
              },
            ],
          }}
        />
      </div>
      <br />
    </div>
  );
}

export default Activity;
