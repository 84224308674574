function Terms(props) {
  return (
    <div className="lcontent">
      <p>
        These Terms and Conditions ("Agreement") govern your access to and use
        of the Online Investment Ecosystem provided on the website
        www.cspillai.com ("Website"). This Agreement sets forth the legally
        binding terms and conditions for your use of the Website. By accessing
        or using the Website, you agree to be bound by this Agreement.
      </p>

      <h2>1. Eligibility:</h2>
      <p>
        You must be at least 18 years of age and have the legal capacity to
        enter into this Agreement. By accessing and using the Website, you
        represent and warrant that you meet these eligibility requirements.
      </p>

      <h2>2. User Account:</h2>
      <p>
        In order to access certain features of the Website, you may be required
        to create an account. You are solely responsible for keeping your
        account information, including your username and password, secure and
        confidential. You must not share your account details with any third
        parties. You are responsible for any actions taken through your account.
      </p>

      <h2>3. Investment Risk:</h2>
      <p>
        The Online Investment Ecosystem provided on the Website involves
        financial risks. You acknowledge and understand that investment
        decisions are solely your responsibility, and csPILLAI™ shall not be
        liable for any losses or damages incurred as a result of your investment
        decisions.
      </p>

      <h2>4. Accuracy of Information:</h2>
      <p>
        csPILLAI™ strives to provide accurate and up-to-date information on the
        Website. However, we do not warrant the accuracy, completeness, or
        reliability of any information or materials on the Website. You
        acknowledge and agree that any reliance on such information or materials
        is at your own risk.
      </p>

      <h2>5. Intellectual Property:</h2>
      <p>
        Copyright, trademark, and other intellectual property laws protect all
        of the content, information, and materials on the Website, including but
        not limited to text, graphics, logos, images, videos, audio clips, data
        compilations, and software. You must not reproduce, distribute, modify,
        or transmit any copyrighted materials without prior written permission
        from csPILLAI™.
      </p>

      <h2>6. Links to Third-Party Websites:</h2>
      <p>
        The Website may contain links to third-party websites that are not owned
        or controlled by csPILLAI™. csPILLAI™ has no control over and assumes no
        responsibility for the content, privacy policies, or practices of any
        third-party websites. By accessing and using such links, you acknowledge
        and agree that CSPillai shall not be liable for any loss or damage
        arising from your use of such third-party websites.
      </p>

      <h2>7. Limitation of Liability:</h2>
      <p>
        In no event shall csPILLAI™, its directors, employees, or affiliates be
        liable to you for any direct, indirect, incidental, special,
        consequential, or punitive damages arising out of or in connection with
        your use of the Website or the Online Investment Ecosystem, regardless
        of the cause of action or the theory of liability.
      </p>

      <h2>8. Termination:</h2>
      <p>
        CSPillai reserves the right to terminate or suspend your access to the
        Website at any time, without prior notice, and for any reason, including
        but not limited to a violation of this Agreement.
      </p>

      <h2>9. Governing Law:</h2>
      <p>
        Without regard to its conflict of law provisions, the laws of the
        country in which csPILLAI™ is based shall govern and govern this
        Agreement.
      </p>

      <h2>10. Entire Agreement:</h2>
      <p>
        This Agreement constitutes the entire agreement between you and
        csPILLAI™ regarding your use of the Website and supersedes any prior
        agreements or understandings.
      </p>
      <br />

      <p>
        By accessing and using the Website, you acknowledge that you have read,
        understood, and agree to be bound by this Agreement.
      </p>
    </div>
  );
}

export default Terms;
