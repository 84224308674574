import React, { useState } from "react";
import "../css/suppliernda.css";
import Form from "../form/Form";
import * as Yup from "yup";
import FormInput from "../form/FormInput";
import Submit from "../form/Submit";
import urls from "../api/urls";
import axios from "axios";

const SupplierNDA = () => {
  const [successMsg, setSuccessMsg] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const validationSchema = Yup.object().shape({
    customerName: Yup.string().required().label("Name"),
    customerEmail: Yup.string()
      .trim()
      .required()
      .label("E-mail")
      .email("E-mail is not valid"),
    phone: Yup.string().required().label("Phone number"),
    customerAddress: Yup.string().required().label("Address"),
    companyType: Yup.string().required().label("Company Type"),
    country: Yup.string().required().label("Country"),
    state: Yup.string().required().label("State"),
    purpose: Yup.string().required().label("Purpose"),
  });

  const formInitialData = {
    customerName: "",
    customerEmail: "",
    phone: "",
    customerAddress: "",
    companyType: "",
    country: "",
    state: "",
    purpose: "",
  };

  const handleSubmit = async (values) => {
    setIsLoading(true);
    setErrorMsg("");
    setSuccessMsg("");
    try {
      await axios.post(urls.supplierNDA, values);
      setSuccessMsg("Supplier NDA has been sent to your mail");
    } catch (error) {
      console.log(error);
      setErrorMsg(
        error?.response?.data?.message ||
          error?.response?.message ||
          error?.response?.description ||
          "Failed to submit form"
      );
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="supplier-nda manrope">
      <h1>Supplier NDA</h1>

      <Form
        onSubmit={handleSubmit}
        initialValues={formInitialData}
        validationSchema={validationSchema}
      >
        <div className="form-container">
          <FormInput name="customerName" label="Name" />
          <FormInput name="customerEmail" label="E-mail" />
          <FormInput name="phone" label="Phone number" />
          <FormInput name="customerAddress" label="Address" />
          <FormInput name="companyType" label="Company type" />
          <FormInput name="country" label="Country" />
          <FormInput name="state" label="State" />
          <FormInput name="purpose" label="Purpose" />
        </div>

        {successMsg && <p style={{ color: "#00786d" }}>{successMsg}</p>}
        {errorMsg && <p style={{ color: "#e33861" }}>{errorMsg}</p>}

        <Submit loading={isLoading} title="Submit" type="submit" />
      </Form>
    </div>
  );
};

export default SupplierNDA;
