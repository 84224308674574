import React, { useRef, useState } from "react";
import Form from "../form/Form";
import * as Yup from "yup";
import "../css/refund.css";
import FormInput from "../form/FormInput";
import Submit from "../form/Submit";
import useClient from "../hooks/useClient";
import urls from "../api/urls";

function Refund() {
  const { isLoading, post } = useClient();

  const receiptRef = useRef();
  const [receipt, setReceipt] = useState();
  const [success, setSuccess] = useState(false);

  const handleSubmit = async (values) => {
    delete values.receipt;
    const formData = new FormData();
    Object.keys(values).forEach((key) => {
      formData.append(key, values[key]);
    });
    formData.append("receipt", receipt);

    const { error, data } = await post(
      urls.investors.services.invest2exit.refund,
      formData
    );
    console.log(error, data);
    if (data) {
      setSuccess(true);
    }
  };

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required().label("First Name"),
    lastName: Yup.string().required().label("Last Name"),
    email: Yup.string().required().label(" Email").email(),
    reason: Yup.string().required().label("Refund for"),
  });

  const formInitialData = {
    firstName: "",
    lastName: "",
    email: "",
    reason: "",
    receipt: "",
  };

  return (
    <div className="refund manrope">
      <h1>Request a refund</h1>

      <div className="formWrapper">
        <Form
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
          initialValues={formInitialData}
        >
          <div className="formSign containerh container">
            <div className="inputs">
              <FormInput
                label="First Name"
                placeholder="First Name"
                name="firstName"
              />
              <FormInput
                label="Last Name"
                placeholder="Last Name"
                name="lastName"
              />
            </div>
            <div className="inputs">
              <FormInput
                label="Email Address"
                placeholder="Email Address"
                name="email"
                type="email"
              />
              <FormInput
                label="Reason For Refund"
                placeholder="Reason for refund"
                name="reason"
              />
            </div>
            <div className="inputs">
              <div className="receipt-input">
                <label htmlFor="receipt">Receipt</label>
                <input
                  name="receipt"
                  placeholder="Receipt"
                  type="file"
                  ref={receiptRef}
                  onChange={() => setReceipt(receiptRef.current.files[0])}
                />
              </div>
            </div>
            {success && (
              <p className="success">Refund request submitted successfully!</p>
            )}
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: 20,
              }}
            >
              <Submit loading={isLoading} title="Submit" />
            </div>
          </div>
        </Form>
      </div>
    </div>
  );
}

export default Refund;
