import React from "react";
import About from "../components/About";
import Arcs from "../components/Arcs";
import Goals from "../components/Goals";
import Hero from "../components/Hero";
import HiW from "../components/HiW";
import Ideology from "../components/Ideology";
import Marquee from "../components/Marquee";
import Objectives from "../components/Objectives";
import Services from "../components/Services";
import What from "../components/What";

function Home(props) {
  return (
    <>
      <Hero el2={"wiu"} />
      <Marquee />
      <Arcs />
      <What />
      <HiW />
      <Services />
      <About />
      <Ideology />
      <Goals />
      <Objectives />
    </>
  );
}

export default Home;
