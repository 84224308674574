export const collages = [
  "Indian Institute of Technology, Bombay",
  "Indian Institute of Management, Bangalore Other",
  "Other",
];

export const positions = [
  "Patent Dept. Internship",
  "Iterative Design Dept. Internship",
  "Liaison Dept. Internship",
  "Launch Planning Dept. Internship",
  "Avant-Garde Dept. Internship",
  "Innovation Dept. Internship",
  "Other",
];

export const departments = [
  "Patent Department",
  "Iterative Design Department",
  "Liaising Department",
  "Launch Planning Department",
  "Avant-Garde Department",
  "Innovation Department",
  "Other",
];
