import RenderLogoWithText from "./RenderLogoWithText";
import "../css/header.css";
import { Link } from "react-router-dom";
import slice1 from "../assets/slice1.png";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import { useState } from "react";
// import RenderLogo from "./RenderLogo";

// const links = [
//   {
//     title: "Home",
//   },
//   {
//     title: "What?",
//   },
//   {
//     title: "Why?",
//   },
//   {
//     title: "How?",
//   },
//   {
//     title: "Services",
//   },
//   {
//     title: "Policies",
//     to: "/legal",
//   },
//   {
//     title: "About",
//   },
//   {
//     title: "Support",
//     to: "/support",
//   },
//   {
//     title: "Follow",
//     link: "https://linktr.ee/cspillai",
//   },
// ];

const links = [
  {
    title: "Home",
  },
  {
    title: "Who we are",
    target: "About",
  },
  {
    title: "What we do",
    target: "What?",
  },
  {
    title: "Why we do it",
    target: "Why?",
  },
  {
    title: "How we do it",
    target: "How?",
  },
  {
    title: "Our Services",
    target: "services",
  },

  {
    title: "How to contact us",
    to: "/support",
  },
  {
    title: "Follow us",
    link: "https://linktr.ee/cspillai",
  },
  {
    title: "Share us",
    target: "/share",
  },
  {
    title: "Subscribe to newsletter",
    target: "footer",
  },
  {
    title: "Policies",
    to: "/legal",
  },
];

function Header(props) {
  const [active, setActive] = useState(links[0].title.toLowerCase());
  const [isOpened, setIsOpened] = useState(false);
  const p = useLocation();

  useEffect(() => {
    const name = p.hash.replace("#", "");
    setIsOpened(false);
    if (name) {
      const el = document.getElementById(name);
      if (!el) return;
      el.scrollIntoView({ behavior: "smooth" });
      setActive(name);
    }
  }, [p.hash]);
  return (
    <header className="container manrope flex bg align-center justify-between">
      <Link to="/#home">
        <RenderLogoWithText className="llap" />
      </Link>
      {/* <img
        src={slice1}
        className="sht desktop"
        alt="Enjoy startup investing, startup building, co-owning, startup selling, IPO exiting, mentoring, manufacturing, and making money( and doing good simultaneously)! And buy your way into startups, scaleups, entrepreneurships, and associateships."
      /> */}
      {/* <RenderLogo className="htopw" /> */}
      <ul className="desktop maj flex">
        {links.map((l, idx) => (
          <li
            onClick={() => {
              if (l.link) window.open(l.link);
            }}
            className={
              (l.target ? l.target.toLowerCase() : l.title.toLowerCase()) ===
              active
                ? "active"
                : ""
            }
            key={idx}
          >
            <Link
              className="hasj"
              to={
                l.link
                  ? "#"
                  : l.to ||
                    (l.target
                      ? `/#${l.target.toLowerCase()}`
                      : `/#${l.title.toLowerCase()}`)
              }
            >
              {l.title}
            </Link>
          </li>
        ))}
      </ul>
      <nav className={`mobile nav ${isOpened ? "opened" : ""}`}>
        <ul className="flex">
          {links.map((l, idx) => (
            <li
              onClick={() => {
                if (l.link) window.open(l.link);
              }}
              className={l.title.toLowerCase() === active ? "active" : ""}
              key={idx}
            >
              <Link
                onClick={() => {
                  if (l.link) window.open(l.link);
                }}
                to={
                  l.link
                    ? "#"
                    : l.to ||
                      (l.target
                        ? `/#${l.target.toLowerCase()}`
                        : `/#${l.title.toLowerCase()}`)
                }
              >
                {l.title}
              </Link>
            </li>
          ))}
        </ul>
      </nav>
      <div onClick={() => setIsOpened(!isOpened)} className="menu mobile">
        <div></div>
        <div></div>
        <div></div>
      </div>
    </header>
  );
}

export default Header;
