import { useEffect } from "react";
import { useContext } from "react";
import { useState } from "react";
import urls from "../../../api/urls";
import Activity from "../../../components/dashboard/Activity";
import Alert from "../../../components/dashboard/Alert";
import DailyActivity from "../../../components/dashboard/DailyActivity";
import Table from "../../../components/dashboard/Table";
import InternContext from "../../../contexts/InternContext";
import useClient from "../../../hooks/useClient";
import {
  days,
  getEnd,
  getHoursWorked,
  getMonday,
  getStart,
} from "../../../utils/dates";

function Dashboard(props) {
  const [weekActivities, setWeekActivities] = useState([]);
  const { get, isLoading } = useClient();
  const { user } = useContext(InternContext);

  const getActivities = async () => {
    const from = getMonday();
    const { data, error } = await get(urls.interns.activity.activities + from);
    if (error) return;
    setWeekActivities(data.data.activities);
  };

  useEffect(() => {
    getActivities();
  }, []);

  const getWeekData = () => {
    const output = [];
    days
      .filter((d) => d !== "Sunday" && d !== "Saturday")
      .forEach((d, idx) => {
        const act = weekActivities.find(
          (a) => days[new Date(a.createdAt).getDay()] === d
        );
        if (!act) {
          output.push({
            day: d,
            from: "No record",
            to: "No record",
            hours: "No record",
            description: "No record",
          });
        } else {
          output.push({
            day: d,
            from: getStart(act),
            to: getEnd(act),
            hours: getHoursWorked(act),
            description: act?.description || "No record",
          });
        }
      });
    return output;
  };

  return (
    <div>
      {!user.verifications.account && (
        <Alert
          className="mobile"
          type="warn"
          message={`You are not approved to start your internship at csPILLAI an email would be sent to ${user.email} upon approving!`}
        />
      )}
      <DailyActivity />
      <Activity activities={weekActivities} />
      <Table
        data={getWeekData()}
        head={[
          {
            title: "Day",
            target: "day",
            className: "tday",
          },
          {
            title: "From",
            target: "from",
            className: "tday",
          },
          {
            title: "To",
            target: "to",
            className: "tday",
          },
          {
            title: "Hours worked",
            target: "hours",
            className: "tday",
          },
          {
            title: "Description",
            target: "description",
            className: "dnd",
          },
        ]}
      />
      <br />
      <br />
      <br />
    </div>
  );
}

export default Dashboard;

// {
// 	"Version": "2012-10-17",
// 	"Statement": [
// 		{
// 			"Sid": "Statement1",
// 			"Principal": {},
// 			"Effect": "Allow",
// 			"Action": [],
// 			"Resource": []
// 		}
// 	]
// }
