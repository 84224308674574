import "../css/support.css";
import spimg from "../assets/support.png";
import sv1 from "../assets/support/01.svg";
import sv2 from "../assets/support/02.svg";
import sv3 from "../assets/support/03.svg";
import sv4 from "../assets/support/04.svg";
import { useEffect, useRef } from "react";

const items = [
  {
    title: "Yes, I'm a VC",
    note: "I am a SCALEUP INVESTOR. I bid and won a startup and purchased this IPO/M&A investment through your INVEST2MULTIPLY service.",
    phones: ["+91 91526 05727", "+91 9702977719", "+91 91672 63429"],
    svg: sv1,
  },

  {
    title: "Yes, I'm an Angel",
    note: "I am a STARTUP INVESTOR. I funded and own a startup and purchased this startup investment through your INVEST2EXIT service.",
    phones: ["+91 91526 05727", "+91 70213 02870", "+91 91672 63429"],
    svg: sv2,
  },
  {
    title: "Yes, I'm a Franchisee",
    note: "I am an ENTREPRENEURIAL INVESTOR. I bought and service a franchise and purchased this franchise through your INVEST2ENTERPRISE service.",
    phones: ["+91 70213 02870", "+91 91672 63429", "+91 91526 05727"],
    svg: sv3,
  },
  {
    title: "Yes, I'm an Incubator",
    note: "I am an INCUBATOR. I invested and am building a startup and purchased this mentorship program through your INVEST2EXCEL service.",
    phones: ["+91 97029 77719", "+91 91526 05727", "+91 91672 63429"],
    svg: sv4,
  },
  {
    title: "Yes, I'm a potential investor",
    note: "I want to talk to csPILLAI® to purchase an alternative investment.",
    phones: ["+91 88507 62306", "tel:+91 70213 02870", "tel:+91 91672 63429"],
    svg: sv4,
  },
];

function Support(props) {
  const containerRef = useRef(null);

  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.scrollIntoView({ behaviour: "smooth" });
    }
  }, []);

  return (
    <div>
      <div ref={containerRef} style={{ height: 60 }}></div>
      <div className="container containerh bg">
        <div className="flex justify-between align-center mfc">
          <div className="txts ubuntu">
            <h2 className="htitle stitle">
              Who should we say is calling or communicating for support?
            </h2>
          </div>
          <br className="mobile" />
          <div className="img">
            <img
              src={spimg}
              alt="csPILLAI ™ Business & Startup Investing Online Platform!"
            />
          </div>
        </div>
      </div>
      <div className="flex manrope cards container containerh mfc">
        {items.map(({ svg, title, note, phones }, idx) => (
          <div key={idx} className="card">
            <div className="img">
              <img src={svg} alt="" />
            </div>
            <div className="cnt_">
              <h2>{title}</h2>
              <p>{note}</p>
              <div className="phones_">
                {phones.map((p, idx) => (
                  <a key={idx} href={`tel:${p}`}>
                    <i className="fa fa-phone"></i>
                  </a>
                ))}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Support;
