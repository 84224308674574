import React from "react";

function Notifications(props) {
  return (
    <div>
      <h2>Notifications</h2>
    </div>
  );
}

export default Notifications;
