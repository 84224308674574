import Home from "./pages/Home";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Share from "./components/Share";
import Legal from "./pages/legal/Legal";
import C404 from "./pages/404";
import Sponsor from "./pages/Sponsor";
import Support from "./pages/Support";
import Token from "./pages/Token";
import Interships from "./routes/Interships";
import NDA from "./pages/NDA";
import MFA from "./pages/MFA";
import Loan from "./pages/Loan";
import ComingSoon from "./pages/ComingSoon";
import Refund from "./pages/Refund";
import SupplierNDA from "./pages/SupplierNDA";

const AppBase = () => {
  return (
    <>
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/token" element={<Token />} />
        <Route path="/legal/*" element={<Legal />} />
        <Route path="/support" element={<Support />} />
        <Route path="/sponsor" element={<Sponsor />} />
        <Route path="/nda" element={<NDA />} />
        <Route path="/loan" element={<Loan />} />
        <Route path="/mfa" element={<MFA />} />
        <Route path="/refund" element={<Refund />} />
        <Route path="/invest2Multiply" element={<ComingSoon />} />
        <Route path="/invest2Excel" element={<ComingSoon />} />
        <Route path="/supplier-nda" element={<SupplierNDA />} />
        <Route path="*" element={<C404 />} />
      </Routes>
      <Share />
      <Footer />
    </>
  );
};

function App(props) {
  return (
    <div className="app_cnt noscroll">
      <BrowserRouter>
        <Routes>
          <Route path="*" element={<AppBase />} />
          <Route path="/internship/*" element={<Interships />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
