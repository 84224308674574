import { useContext } from "react";
import { useState } from "react";
import urls from "../../../api/urls";
import InternContext from "../../../contexts/InternContext";
import "../../../css/login.css";
import useClient from "../../../hooks/useClient";
import store from "../../../utils/store";
import { Link, useNavigate } from "react-router-dom";

function Login(props) {
  const { setUser } = useContext(InternContext);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  // const [email, setEmail] = useState("ismaildalhatu442@gmail.com");
  // const [password, setPassword] = useState("IsmailDalhatu123?");
  const [errorMessage, setErrorMessage] = useState("");
  const { isLoading, post } = useClient();

  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    setErrorMessage("");
    if (!email || !password) return setErrorMessage("Fill out all fields");
    const { data, error } = await post(urls.interns.login, {
      email,
      password,
    });
    if (error) return setErrorMessage(error.message);
    store.setTokens(data.data.tokens);
    setUser(data.data.profile);
    navigate("/internship");
  };

  return (
    <div className="container_auth manrope">
      <form onSubmit={handleLogin} id="resetForm">
        <div className="logo">
          <svg
            width="49"
            height="54"
            viewBox="0 0 49 54"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M38.9375 12.2154L24.6332 7.56553L8.9212 16.0602L10.9185 36.6331L24.6332 45.3932L39.8125 35.1731L40.4783 30.9257L24.6332 41.1458V21.9002L49 8.72527L48.3342 14.0692L29.4266 24.6875L29.0272 32.5185L47.9348 21.9002L45.6712 38.624L24.6332 53.2241L4.26087 39.8186L0 11.9456L23.8342 0L48.3342 6.98021L38.9375 12.2154Z"
              fill="url(#paint0_linear_24_70)"
            />
            <defs>
              <linearGradient
                id="paint0_linear_24_70"
                x1="24.2337"
                y1="2.38911"
                x2="24.2337"
                y2="53.2241"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#F64C3B" />
                <stop offset="1" stopColor="#B80CB6" />
              </linearGradient>
            </defs>
          </svg>
        </div>

        <h2>Login</h2>
        <span>Sign in to continue to csPILLAI Dashboard</span>
        <label htmlFor="email">Email</label>
        <input
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
          id="email"
          type="email"
          name="email"
        />
        <br />
        <label htmlFor="password">Password</label>
        <input
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
          id="password"
          type="password"
          name="password"
        />
        <p id="message" className="message">
          {errorMessage}
        </p>
        <input
          disabled={isLoading}
          id="submit"
          type="submit"
          value={isLoading ? "Loading..." : "Login"}
        />
        <Link className="link_sr kko" to="/internship/recover-account">
          Forgot Password?
        </Link>
      </form>
    </div>
  );
}

export default Login;
