function RefundPolicy(props) {
  return (
    <div className="lcontent">
      <p>Last updated: [23.08.23]</p>
      <p>
        Thank you for using the 'Online Investment Ecosystem' website at
        www.cspillai.com ("website"). This Refund Policy explains our refund
        process and applies to all purchases made on our website.
      </p>

      <h2>1. Subscription and Service Fees</h2>
      <p>
        If you have subscribed to any paid services or paid a fee for access to
        certain features or content on our website, the following refund policy
        applies:
      </p>
      <ol type="a">
        <li>
          Monthly Subscriptions: You may cancel your monthly subscription at any
          time. However, we do not provide refunds for any unused portion of the
          current month.
        </li>
        <li>
          Annual Subscriptions: If you have purchased an annual subscription,
          you may cancel within the first 30 days and receive a full refund.
          After 30 days, no refunds will be provided.
        </li>
        <li>
          Service Fees: Service fees for specific services or features are
          non-refundable unless otherwise stated.
        </li>
      </ol>

      <h2>2. Digital Products and Downloads</h2>
      <p>
        We may offer digital products, such as e-books or online courses, for
        purchase on our website. Due to the nature of digital products, we do
        not provide refunds for these items once the purchase is complete and
        the product has been accessed or downloaded.
      </p>

      <h2>3. Billing Disputes</h2>
      <p>
        If you believe there has been an error or unauthorized charge on your
        billing statement, please contact our customer support within 30 days of
        the transaction. We will investigate the issue and, if necessary,
        provide a refund or credit to resolve the dispute.
      </p>

      <h2>4. Refund Process</h2>
      <p>
        To request a refund or dispute a charge, please contact our customer
        support at{" "}
        <a href="https://cspillai.com/refund" target="__blank">
          cspillai.com/refund
        </a>
        . Please provide your order details and a brief explanation of the
        reason for the refund request. We will review your request and respond
        as soon as possible, typically within 30 days.
      </p>

      <h2>5. Exceptions</h2>
      <p>
        We reserve the right to refuse refunds or issue partial refunds in the
        following circumstances:
      </p>
      <ul>
        <li>Violation of our Terms of Service or Acceptable Use Policy</li>
        <li>Abuse of our refund policy or excessive refund requests</li>
        <li>
          Failure to provide adequate information to process the refund request
        </li>
      </ul>

      <p>
        Please note that the refund policy mentioned in this document does not
        affect your statutory rights under applicable consumer protection laws.
      </p>

      <p>
        If you have any questions or concerns about this Refund Policy, please
        contact us at <a href="mailto:mail@cspillai.com">mail@cspillai.com</a>.
      </p>
    </div>
  );
}

export default RefundPolicy;
