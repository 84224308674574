import "../../css/dashboard/accountInfo.css";
import profile from "../../assets/profile.png";
import RenderAgreemnt from "./RenderAgreemnt";
import { Link } from "react-router-dom";
import Alert from "./Alert";
import { useContext } from "react";
import RenderImageProfile from "./RenderImageProfile";
import InternContext from "../../contexts/InternContext";

const navs = [
  {
    to: "/internship/support",
    renderSvg: () => (
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="12" cy="12" r="10" fill="#C4C4C4" />
        <path
          d="M10.797 13.1433C10.797 12.6179 11.198 12.0122 12 11.326C12.3553 11.0151 12.5939 10.7739 12.7157 10.6023C12.8376 10.4201 12.8985 10.211 12.8985 9.97515C12.8985 9.68567 12.8071 9.44981 12.6244 9.26754C12.4416 9.08528 12.2132 8.99415 11.9391 8.99415C11.3807 8.99415 10.9594 9.40156 10.6751 10.2164L9 9.44444C9.2132 8.65107 9.57868 8.04532 10.0964 7.62719C10.6244 7.20906 11.2589 7 12 7C12.8528 7 13.5635 7.26267 14.132 7.78801C14.7107 8.31335 15 8.98879 15 9.81433C15 10.2539 14.9036 10.6506 14.7107 11.0044C14.5178 11.3582 14.1929 11.7442 13.736 12.1623C13.2792 12.5804 12.9848 12.8699 12.8528 13.0307C12.731 13.1915 12.6701 13.3738 12.6701 13.5775C12.6701 13.6311 12.6802 13.7437 12.7005 13.9152H10.9645C10.8528 13.6472 10.797 13.3899 10.797 13.1433ZM10.4619 16.5044C10.4619 16.0863 10.599 15.7325 10.8731 15.443C11.1472 15.1535 11.4873 15.0088 11.8934 15.0088C12.2995 15.0088 12.6345 15.1535 12.8985 15.443C13.1726 15.7325 13.3096 16.0863 13.3096 16.5044C13.3096 16.9332 13.1726 17.2924 12.8985 17.5819C12.6345 17.8606 12.2995 18 11.8934 18C11.4873 18 11.1472 17.8606 10.8731 17.5819C10.599 17.2924 10.4619 16.9332 10.4619 16.5044Z"
          fill="white"
        />
      </svg>
    ),
  },
  {
    to: "/internship/notifications",
    renderSvg: () => (
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M20.19 14.06L19.06 12.18C18.81 11.77 18.59 10.98 18.59 10.5V8.63C18.59 5 15.64 2.05 12.02 2.05C8.39 2.06 5.44 5 5.44 8.63V10.49C5.44 10.97 5.22 11.76 4.98 12.17L3.85 14.05C3.42 14.78 3.32 15.61 3.59 16.33C3.86 17.06 4.47 17.64 5.27 17.9C6.35 18.26 7.44 18.52 8.55 18.71C8.66 18.73 8.77 18.74 8.88 18.76C9.02 18.78 9.17 18.8 9.32 18.82C9.58 18.86 9.84 18.89 10.11 18.91C10.74 18.97 11.38 19 12.02 19C12.65 19 13.28 18.97 13.9 18.91C14.13 18.89 14.36 18.87 14.58 18.84C14.76 18.82 14.94 18.8 15.12 18.77C15.23 18.76 15.34 18.74 15.45 18.72C16.57 18.54 17.68 18.26 18.76 17.9C19.53 17.64 20.12 17.06 20.4 16.32C20.68 15.57 20.6 14.75 20.19 14.06ZM12.75 10C12.75 10.42 12.41 10.76 11.99 10.76C11.57 10.76 11.23 10.42 11.23 10V6.9C11.23 6.48 11.57 6.14 11.99 6.14C12.41 6.14 12.75 6.48 12.75 6.9V10Z"
          fill="#C4C4C4"
        />
        <path
          d="M14.83 20.01C14.41 21.17 13.3 22 12 22C11.21 22 10.43 21.68 9.88 21.11C9.56 20.81 9.32 20.41 9.18 20C9.31 20.02 9.44 20.03 9.58 20.05C9.81 20.08 10.05 20.11 10.29 20.13C10.86 20.18 11.44 20.21 12.02 20.21C12.59 20.21 13.16 20.18 13.72 20.13C13.93 20.11 14.14 20.1 14.34 20.07C14.5 20.05 14.66 20.03 14.83 20.01Z"
          fill="#C4C4C4"
        />
      </svg>
    ),
    badge: 2,
  },
];

function AccountInfo(props) {
  const { user } = useContext(InternContext);
  return (
    <div className="acct_inf manrope">
      <div className="links flex">
        {navs.map(({ renderSvg, badge, to }, idx) => (
          <Link
            className="act_link flex justify-center align-center"
            to={to}
            key={idx}
          >
            {renderSvg()}
            {badge && <div className="badge">{badge}</div>}
          </Link>
        ))}
        <Link
          to="/internship/profile"
          className="act_link flex justify-center align-center"
        >
          <RenderImageProfile imageOnly />
        </Link>
      </div>
      <RenderImageProfile />
      <h2 className="name">
        {user.firstName} {user.lastName}
      </h2>
      <RenderAgreemnt stat={0} />
      <div className="sp alerts">
        <h3 style={{ marginTop: 10 }}>Inbox</h3>
        <Link to="/internship/notifications">
          <div className="flex">
            <h4
              style={{
                color: "#333333",
                marginLeft: "auto",
                fontSize: "0.8em",
                cursor: "pointer",
              }}
            >
              View all
            </h4>
          </div>
        </Link>
        {/* <Alert type="success" message="Success" />
        <Alert type="info" message="Info" /> */}
        {!user.verifications.account && (
          <Alert
            type="warn"
            message={`You are not approved to start your internship at csPILLAI an email would be sent to ${user.email} upon approving!`}
          />
        )}
        {/* <Alert type="error" message="Error" /> */}
      </div>
    </div>
  );
}

export default AccountInfo;
