import React from "react";
import { useState } from "react";
import { useContext } from "react";
import Table from "../../../components/dashboard/Table";
import InternContext from "../../../contexts/InternContext";
import { getTo, getWeeks } from "../../../utils/dates";
import { Link } from "react-router-dom";

function Reports(props) {
  const { agreement } = useContext(InternContext);
  const [weeks, setWeeks] = useState(getWeeks(agreement));

  const getTableData = () => {
    const output = [];
    weeks.forEach((w, idx) => {
      output.push({
        from: w,
        weekId: `Week ${idx + 1}`,
        to: getTo(w),
      });
    });
    return output;
  };
  return (
    <div>
      <Table
        data={getTableData()}
        head={[
          {
            title: "#",
            target: "weekId",
          },
          {
            title: "Starts",
            target: "from",
          },
          {
            title: "End",
            target: "to",
          },
          {
            title: "",
            target: "",
            render: (r) => (
              <Link
                className="gradient btn"
                to={`/internship/reports/${r.from}`}
              >
                View Report
              </Link>
            ),
          },
        ]}
      />
    </div>
  );
}

export default Reports;
