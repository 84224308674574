export const RenderCheck = () => (
  <svg
    className="check"
    width="14"
    height="12"
    viewBox="0 0 14 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.5 0.75H1V11.25H11.5V7.75"
      stroke="#4D5959"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4 5.75L6.5 8.25L12.5 1.75"
      stroke="#4D5959"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const routes1 = {
  title: "Menu",
  elms: [
    {
      title: "Dashboard",
      to: "/internship/",
      renderSvg: () => (
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M18.3333 7.10033V3.31699C18.3333 2.14199 17.8 1.66699 16.475 1.66699H13.1083C11.7833 1.66699 11.25 2.14199 11.25 3.31699V7.09199C11.25 8.27532 11.7833 8.74199 13.1083 8.74199H16.475C17.8 8.75032 18.3333 8.27533 18.3333 7.10033Z"
            fill="#4D5959"
          />
          <path
            d="M18.3333 16.475V13.1083C18.3333 11.7833 17.8 11.25 16.475 11.25H13.1083C11.7833 11.25 11.25 11.7833 11.25 13.1083V16.475C11.25 17.8 11.7833 18.3333 13.1083 18.3333H16.475C17.8 18.3333 18.3333 17.8 18.3333 16.475Z"
            fill="#4D5959"
          />
          <path
            d="M8.74935 7.10033V3.31699C8.74935 2.14199 8.21602 1.66699 6.89102 1.66699H3.52435C2.19935 1.66699 1.66602 2.14199 1.66602 3.31699V7.09199C1.66602 8.27532 2.19935 8.74199 3.52435 8.74199H6.89102C8.21602 8.75032 8.74935 8.27533 8.74935 7.10033Z"
            fill="#4D5959"
          />
          <path
            d="M8.74935 16.475V13.1083C8.74935 11.7833 8.21602 11.25 6.89102 11.25H3.52435C2.19935 11.25 1.66602 11.7833 1.66602 13.1083V16.475C1.66602 17.8 2.19935 18.3333 3.52435 18.3333H6.89102C8.21602 18.3333 8.74935 17.8 8.74935 16.475Z"
            fill="#4D5959"
          />
        </svg>
      ),
    },
    {
      to: "/internship/logs",
      title: "Logs",
      renderSvg: RenderCheck,
    },
    {
      to: "/internship/reports",
      title: "Reports",
      renderSvg: RenderCheck,
    },
    {
      to: "/internship/certificate",
      title: "Certificate",
      renderSvg: RenderCheck,
    },
  ],
};

export const routes2 = {
  title: "Main",
  elms: [
    {
      to: "/internship/profile",
      title: "Profile",
      renderSvg: () => (
        <svg
          width="16"
          height="17"
          viewBox="0 0 16 17"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8 16.15C11.9764 16.15 15.2 12.7249 15.2 8.49998C15.2 4.27507 11.9764 0.849998 8 0.849998C4.0236 0.849998 0.8 4.27507 0.8 8.49998C0.8 12.7249 4.0236 16.15 8 16.15ZM8 17C12.4184 17 16 13.1945 16 8.49998C16 3.80544 12.4184 0 8 0C3.5816 0 0 3.80544 0 8.49998C0 13.1945 3.5816 17 8 17Z"
            fill="#4D5959"
          />
          <path
            d="M3.19922 13.4428C3.19922 13.0038 3.50802 12.6328 3.91922 12.5843C7.00522 12.2214 9.00722 12.2541 12.0864 12.5924C12.2402 12.6096 12.3859 12.6739 12.5058 12.7777C12.6257 12.8814 12.7146 13.0199 12.7616 13.1764C12.8086 13.3329 12.8117 13.5006 12.7705 13.6589C12.7293 13.8172 12.6456 13.9594 12.5296 14.068C8.89562 17.4336 6.81882 17.3873 3.45522 14.0714C3.29122 13.9099 3.19922 13.6804 3.19922 13.4433V13.4428Z"
            fill="#4D5959"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12.0468 13.0153C8.99198 12.6795 7.01878 12.6481 3.96438 13.0072C3.86384 13.0197 3.77115 13.0711 3.70406 13.1516C3.63698 13.2321 3.6002 13.3361 3.60078 13.4437C3.60078 13.5652 3.64838 13.6804 3.72878 13.7603C5.39598 15.4033 6.65918 16.1458 7.89398 16.1505C9.13318 16.1552 10.4644 15.4186 12.2684 13.7484C12.3257 13.6942 12.367 13.6235 12.3871 13.5448C12.4073 13.4662 12.4056 13.383 12.3821 13.3054C12.3586 13.2278 12.3145 13.1591 12.2549 13.1077C12.1954 13.0562 12.1231 13.0243 12.0468 13.0157V13.0153ZM3.87678 12.1623C6.99518 11.7955 9.02638 11.8287 12.1296 12.17C12.3609 12.1956 12.5802 12.2924 12.7605 12.4484C12.9409 12.6044 13.0745 12.8129 13.1451 13.0484C13.2156 13.2838 13.22 13.5361 13.1577 13.7742C13.0954 14.0122 12.9692 14.2259 12.7944 14.3889C10.9644 16.0838 9.44038 17.0069 7.89158 17.0005C6.33838 16.9945 4.88158 16.0557 3.18518 14.3833C3.06372 14.2631 2.9669 14.1176 2.90076 13.9559C2.83462 13.7941 2.80059 13.6196 2.80078 13.4433C2.8002 13.1264 2.90966 12.8204 3.10821 12.584C3.30675 12.3475 3.58042 12.1972 3.87678 12.1619V12.1623Z"
            fill="#4D5959"
          />
          <path
            d="M11.2008 6.80038C11.2008 7.70212 10.8636 8.56692 10.2635 9.20454C9.66341 9.84216 8.84947 10.2004 8.00078 10.2004C7.15209 10.2004 6.33816 9.84216 5.73804 9.20454C5.13792 8.56692 4.80078 7.70212 4.80078 6.80038C4.80078 5.89865 5.13792 5.03385 5.73804 4.39623C6.33816 3.7586 7.15209 3.40039 8.00078 3.40039C8.84947 3.40039 9.66341 3.7586 10.2635 4.39623C10.8636 5.03385 11.2008 5.89865 11.2008 6.80038Z"
            fill="#4D5959"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8.00078 9.35038C8.6373 9.35038 9.24775 9.08172 9.69784 8.6035C10.1479 8.12529 10.4008 7.47668 10.4008 6.80038C10.4008 6.12408 10.1479 5.47548 9.69784 4.99727C9.24775 4.51905 8.6373 4.25039 8.00078 4.25039C7.36426 4.25039 6.75381 4.51905 6.30372 4.99727C5.85364 5.47548 5.60078 6.12408 5.60078 6.80038C5.60078 7.47668 5.85364 8.12529 6.30372 8.6035C6.75381 9.08172 7.36426 9.35038 8.00078 9.35038ZM8.00078 10.2004C8.84947 10.2004 9.66341 9.84216 10.2635 9.20454C10.8636 8.56692 11.2008 7.70212 11.2008 6.80038C11.2008 5.89865 10.8636 5.03385 10.2635 4.39623C9.66341 3.7586 8.84947 3.40039 8.00078 3.40039C7.15209 3.40039 6.33816 3.7586 5.73804 4.39623C5.13792 5.03385 4.80078 5.89865 4.80078 6.80038C4.80078 7.70212 5.13792 8.56692 5.73804 9.20454C6.33816 9.84216 7.15209 10.2004 8.00078 10.2004Z"
            fill="#4D5959"
          />
        </svg>
      ),
    },
    // {
    //   to: "/internship/documents",
    //   title: "Documents",
    //   renderSvg: () => (
    //     <svg
    //       width="13"
    //       height="17"
    //       viewBox="0 0 13 17"
    //       fill="none"
    //       xmlns="http://www.w3.org/2000/svg"
    //     >
    //       <path
    //         d="M12.8264 4.26328C12.9372 4.37712 13 4.5308 13 4.69208V16.3929C13 16.7287 12.7359 17 12.4091 17H0.590909C0.264062 17 0 16.7287 0 16.3929V0.607143C0 0.271317 0.264062 0 0.590909 0H8.43338C8.59034 0 8.74176 0.064509 8.85256 0.178348L12.8264 4.26328ZM11.6372 4.97098L8.16193 1.40022V4.97098H11.6372ZM2.95455 7.9308C2.91537 7.9308 2.87779 7.9468 2.85009 7.97526C2.82238 8.00373 2.80682 8.04233 2.80682 8.08259V8.9933C2.80682 9.03356 2.82238 9.07217 2.85009 9.10063C2.87779 9.1291 2.91537 9.14509 2.95455 9.14509H10.0455C10.0846 9.14509 10.1222 9.1291 10.1499 9.10063C10.1776 9.07217 10.1932 9.03356 10.1932 8.9933V8.08259C10.1932 8.04233 10.1776 8.00373 10.1499 7.97526C10.1222 7.9468 10.0846 7.9308 10.0455 7.9308H2.95455ZM2.95455 10.5112C2.91537 10.5112 2.87779 10.5272 2.85009 10.5556C2.82238 10.5841 2.80682 10.6227 2.80682 10.6629V11.5737C2.80682 11.6139 2.82238 11.6525 2.85009 11.681C2.87779 11.7095 2.91537 11.7254 2.95455 11.7254H6.35227C6.39145 11.7254 6.42903 11.7095 6.45673 11.681C6.48444 11.6525 6.5 11.6139 6.5 11.5737V10.6629C6.5 10.6227 6.48444 10.5841 6.45673 10.5556C6.42903 10.5272 6.39145 10.5112 6.35227 10.5112H2.95455Z"
    //         fill="#4D5959"
    //       />
    //     </svg>
    //   ),
    // },
    // {
    //   title: "Support",
    //   to: "/internship/support",
    //   renderSvg: () => (
    //     <svg
    //       width="17"
    //       height="17"
    //       viewBox="0 0 17 17"
    //       fill="none"
    //       xmlns="http://www.w3.org/2000/svg"
    //     >
    //       <path
    //         d="M15.4545 5.40852C15.8644 5.40852 16.2575 5.57136 16.5473 5.8612C16.8372 6.15104 17 6.54415 17 6.95405V10.0451C17 10.455 16.8372 10.8481 16.5473 11.138C16.2575 11.4278 15.8644 11.5906 15.4545 11.5906H14.6339C14.4455 13.0846 13.7184 14.4585 12.5891 15.4545C11.4597 16.4504 10.0057 17 8.5 17V15.4545C9.72964 15.4545 10.9089 14.966 11.7784 14.0964C12.6479 13.2269 13.1364 12.0476 13.1364 10.8179V6.18129C13.1364 4.95159 12.6479 3.77225 11.7784 2.90272C10.9089 2.0332 9.72964 1.5447 8.5 1.5447C7.27036 1.5447 6.09108 2.0332 5.2216 2.90272C4.35211 3.77225 3.86364 4.95159 3.86364 6.18129V11.5906H1.54545C1.13557 11.5906 0.742482 11.4278 0.452653 11.138C0.162824 10.8481 0 10.455 0 10.0451V6.95405C0 6.54415 0.162824 6.15104 0.452653 5.8612C0.742482 5.57136 1.13557 5.40852 1.54545 5.40852H2.36609C2.55466 3.91469 3.28183 2.541 4.41117 1.54522C5.54051 0.549438 6.99438 0 8.5 0C10.0056 0 11.4595 0.549438 12.5888 1.54522C13.7182 2.541 14.4453 3.91469 14.6339 5.40852H15.4545ZM5.22364 11.4245L6.04273 10.1139C6.77919 10.5753 7.63096 10.8193 8.5 10.8179C9.36903 10.8193 10.2208 10.5753 10.9573 10.1139L11.7764 11.4245C10.7944 12.0397 9.65874 12.3652 8.5 12.3634C7.34126 12.3652 6.20557 12.0397 5.22364 11.4245Z"
    //         fill="#4D5959"
    //       />
    //     </svg>
    //   ),
    // },
  ],
};

export const links3 = [
  {
    to: "/internship/notifications",
    title: "Notifications",
  },
  {
    to: "/internship/sign-agrement",
    title: "Sign Agreement",
    from: "/sign-agreement",
  },
];
