import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import urls from "../../../api/urls";
import Table from "../../../components/dashboard/Table";
import useClient from "../../../hooks/useClient";
import { getDay, getEnd, getHoursWorked, getStart } from "../../../utils/dates";

function Logs(props) {
  const [logs, setLogs] = useState([]);
  const { isLoading, get } = useClient();
  const getLogs = async () => {
    const { data, error } = await get(
      urls.interns.activity.activities.replace("?from=", "")
    );
    if (error) return;
    setLogs(data.data.activities);
  };

  const getData = () => {
    const output = [];

    logs.forEach((l) => {
      output.push({
        from: getStart(l),
        end: getEnd(l),
        description: l.description,
        hours: getHoursWorked(l),
        day: getDay(l.createdAt),
      });
    });

    return output;
  };

  useEffect(() => {
    getLogs();
  }, []);

  return (
    <div>
      <Table
        data={getData()}
        head={[
          {
            title: "Day",
            target: "day",
            className: "day",
          },
          {
            title: "From",
            target: "from",
            className: "tday",
          },
          {
            title: "To",
            target: "end",
            className: "tday",
          },
          {
            title: "Hours worked",
            target: "hours",
            className: "tday",
          },
          {
            title: "Description",
            target: "description",
            className: "dnd",
          },
        ]}
      />
    </div>
  );
}

export default Logs;
