function Disclaimer(props) {
  return (
    <div className="lcontent">
      <p>Last updated: [23.08.23]</p>
      <p>
        The information provided on the 'Online Investment Ecosystem' website at
        www.cspillai.com ("website") is intended for informational purposes
        only. The website owner(s) make no representations or warranties
        regarding the accuracy, reliability, or completeness of any information
        provided on the website.
      </p>

      <h2>1. No Financial Advice</h2>
      <p>
        The website does not provide financial advice. The information available
        on the website is general in nature and does not take into account your
        specific financial situation, objectives, or risk tolerance. You should
        consult with a qualified financial advisor or professional before making
        any investment decisions or taking any financial actions.
      </p>

      <h2>2. Investment Risks</h2>
      <p>
        Investing in securities, stocks, commodities, or other alternative
        financial instruments involves taking risks. The value of investments
        can fluctuate, and past performance is not indicative of future results.
        The website owner(s) are not responsible for any financial losses,
        damages, or liabilities incurred as a result of the use of or reliance
        on the information provided on the website.
      </p>

      <h2>3. No Guarantee of Earnings</h2>
      <p>
        The website does not guarantee or promise any specific investment
        returns, earnings, or financial outcomes. The potential earnings or
        results discussed on the website are illustrative examples and may not
        represent the actual experiences of individuals. Your actual returns may
        vary significantly based on numerous factors, including market
        conditions, investment strategy, and individual circumstances.
      </p>

      <h2>4. Third-Party Content and Links</h2>
      <p>
        The website may contain third-party content, links, or advertisements.
        The website owner(s) do not endorse or control the content, accuracy, or
        reliability of these third-party websites or their offerings. Your
        interactions with these third parties are at your own risk, and the
        website owner(s) shall not be held liable for any losses, damages, or
        liabilities resulting from such interactions.
      </p>

      <h2>5. Personal Responsibility</h2>
      <p>
        You acknowledge and agree that any investment decisions or actions you
        take based on the information provided on the website are your sole
        responsibility. You should conduct your own due diligence, research, and
        analysis before making any investment decisions.
      </p>

      <h2>6. Changes and Updates</h2>
      <p>
        The website owner(s) may modify, update, or remove any information on
        the website at any time without prior notice. Furthermore, they do not
        guarantee that the website will be accessible or error-free at all
        times.
      </p>

      <h2>7. Legal and Financial Advice</h2>
      <p>
        The website owner(s) recommend consulting with legal, financial, and
        other professionals regarding your specific situation before making any
        investment or financial decisions. The information provided on the
        website should not be considered a substitute for professional advice.
      </p>

      <h2>8. Acceptance of the Disclaimer</h2>
      <p>
        By using the website, you acknowledge that you have read, understood,
        and accepted this Earnings Disclaimer. If you do not agree with this
        disclaimer, your sole remedy is to discontinue using the website.
      </p>

      <p>
        If you have any questions or concerns about this Earnings Disclaimer,
        please contact us at{" "}
        <a href="mailto:mail@cspillai.com">mail@cspillai.com</a>.
      </p>
    </div>
  );
}

export default Disclaimer;
