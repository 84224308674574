import "../css/what.css";
import Title from "./Title";
import Check from "./Check";

const items = [
  "Visit the 'Services' section to identify your investor type.",
  "Assess your specific investor profile.",
  "Select a service that aligns with your profile.",
  "Navigate to the chosen service's site.",
  "Create your account to get started.",
  "Digitally sign the agreement(s).",
  "Invest your funds and track progress in real-time.",
  "Manage your business remotely or serve as a mentor.",
  "Enjoy returns on your investment according to the CAGR period.",
];

function HiW() {
  return (
    <div id="how?" className="container what_cnt flex mfc justify-between">
      <div className="pm">
        <Title subtitle="csPILLAI" title="How does this work? It's simple." />
        <p className="manrope">
          At csPILLAI®, our online investment ecosystem offers user-friendly
          platforms and a variety of alternative investment opportunities driven
          by sustainable and social practices. Our goal is to promote well-being
          for both people and nature while generating returns for investors.
          Convenience is key with csPILLAI®, allowing you to meet your
          investment needs from anywhere.
          <br />
          <br />
          Investing with csPILLAI® is straightforward. We integrate
          sustainability and social responsibility into every step of the
          process. Here's how it works:
        </p>

        <ul className="ulw manrope">
          {items.map((i, idx) => (
            <li key={idx}>
              <Check />
              {i}
            </li>
          ))}
        </ul>

        <p className="manrope">
          By following these steps, you can seamlessly integrate into our
          investment ecosystem, enjoying the benefits of sustainable and
          socially responsible investing tailored to your needs.
        </p>
      </div>
      <div id="wiu" className="pm">
        <div className="iframe">
          <iframe
            src="https://www.youtube.com/embed/0Kqtxcdtt1g?si=eb-SkT-3xZ3TS0q-"
            title="How it works"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
        <p className="manrope">
          If you require further assistance, simply submit your details and
          queries via WhatsApp at +91 8850762306 to our dedicated 'Admins'. They
          will promptly review your message and respond to you as soon as
          possible.
        </p>
      </div>
    </div>
  );
}

export default HiW;
