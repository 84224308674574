import Arcs from "../components/Arcs";
import Hero from "../components/Hero";
import Sp01 from "../components/Sp01";
import Sp02 from "../components/Sp02";
import "../css/sponsor.css";

const arcs = [
  {
    title: "Female-driven firms",
    subtitle: "with several female-driven Tech firms emerging recently",
    bg: "#FFF2F2",
    svg: () => (
      <svg
        width="47"
        height="34"
        viewBox="0 0 47 34"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M46.4906 0.00415459L35.1833 1.35986C34.8087 1.40506 34.6498 1.86261 34.9166 2.1281L38.2826 5.47783L25.4541 18.2441L19.6756 12.4993C19.318 12.1434 18.7447 12.1491 18.3927 12.4993L0.131919 30.6771C0.0474079 30.762 0 30.8767 0 30.9963C0 31.1158 0.0474079 31.2305 0.131919 31.3154L2.68627 33.8687C2.86224 34.0438 3.15173 34.0438 3.3277 33.8687L19.0341 18.2441L24.807 23.9889C25.1646 24.3392 25.7379 24.3392 26.0898 23.9889L41.4954 8.6694L44.8615 12.0191C44.9218 12.0789 44.9978 12.1206 45.0807 12.1395C45.1637 12.1584 45.2503 12.1537 45.3307 12.1261C45.4112 12.0984 45.4822 12.0488 45.5357 11.983C45.5892 11.9172 45.6231 11.8377 45.6335 11.7536L46.9958 0.501248C47.0356 0.213159 46.7858 -0.035387 46.4906 0.00415459Z"
          fill="#F64C3B"
        />
      </svg>
    ),
  },
  {
    title: "Female-driven firms",
    subtitle: "women own 36% of small businesses worldwide",
    bg: "#F1F1F1",
    value: "36%",
  },
  {
    title: "Female-driven firms",
    subtitle:
      "47% of women starting a business in the last year compared to 44% of men",
    bg: "#E2F3FF",
    value: "47%",
  },
];

function Sponsor(props) {
  return (
    <div className="sponsor">
      <Hero
        to1="https://manufacturingbymasses.cspillai.com/sponsor"
        to2={"https://www.youtube.com/watch?v=dZXpEDYt9a8"}
        sb={false}
        sub="Promoting Women Entrepreneurship And Rural Development Through cADRE. "
        t1="Sponsor"
        t2="a woman"
        t3="entrepreneur today."
      />
      <Arcs arcs={arcs} />
      <Sp01 />
      <Sp02 />
    </div>
  );
}

export default Sponsor;
