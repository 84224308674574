import { useState } from "react";
import { useEffect } from "react";
import Loader from "../components/Loader";
import InternContext from "../contexts/InternContext";
import useClient from "../hooks/useClient";
import Auth from "./auth/Auth";
import DashboardInTern from "./dashboard/Dashboard";

function Interships(props) {
  const [agreement, setAgreement] = useState(null);
  const [user, setUser] = useState(null);
  const [reports, setReports] = useState([]);
  const [routeTitle, setRouteTitle] = useState("Home");
  const { isLoading, restoreUser, getAgreement, getReports } = useClient();

  const getUser = async () => {
    const profile = await restoreUser();
    setUser(profile);
  };

  const getAndSetAgreement = async () => {
    const agreement = await getAgreement();
    setAgreement(agreement);
  };

  const getAndSetReports = async () => {
    const reports = await getReports();
    setReports(reports);
  };

  useEffect(() => {
    getUser();
  }, []);

  useEffect(() => {
    if (user) {
      getAndSetAgreement();
      getAndSetReports();
    }
  }, [user]);

  if (isLoading) return <Loader className="appLoader" loading />;

  return (
    <InternContext.Provider
      value={{ user, setUser, routeTitle, setRouteTitle, agreement, reports }}
    >
      {user ? <DashboardInTern /> : <Auth />}
    </InternContext.Provider>
  );
}

export default Interships;
