import { useState } from "react";
import * as Yup from "yup";
import urls from "../api/urls";
import "../css/nda.css";
import Form from "../form/Form";
import FormInput from "../form/FormInput";
import Submit from "../form/Submit";
import useClient from "../hooks/useClient";

const validationSchema = Yup.object().shape({
  firstName: Yup.string().required().label("First Name"),
  lastName: Yup.string().required().label("Last Name"),
  eemail: Yup.string().required().label("Email").email(),
  password: Yup.string().required().label("Password").min(10),
  phoneNumber: Yup.string().required().label("Phone Number"),
  address: Yup.string().required().label("Address"),
  age: Yup.number().required().label("Age").min(1),
});

const formInitialData = {
  firstName: "",
  lastName: "",
  eemail: "",
  password: "",
  phoneNumber: "",
  address: "",
  age: "",
};

function MFA(props) {
  const { post, isLoading } = useClient();
  const handleSubmit = async ({
    eemail: email,
    password,
    firstName,
    lastName,
    address,
    phoneNumber,
    age,
  }) => {
    try {
      let token = "";
      const signupRes = await post(urls.investors.register, {
        email,
        password,
        firstName,
        lastName,
        phoneNumber,
        age,
      });
      if (signupRes.error) {
        const loginRes = await post(urls.investors.login, { email, password });
        token = loginRes.data.data.tokens.accessToken;
        if (loginRes.error)
          return alert(
            "You already have an account associated with this email try using the password you created account with."
          );
      } else {
        token = signupRes.data.data.tokens.accessToken;
      }
      const aggRes = await post(
        urls.investors.services.invest2enterprise.mfa.signAgreement,
        { address },
        {
          headers: {
            "x-access-token": token,
          },
        }
      );
      if (aggRes.error) return alert(aggRes.error.message);
      alert(`An email would be sent to ${email} with a sign URL`);
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <>
      <div className="container bg containerh">
        <div style={{ height: 60 }}></div>
        <div className=" form_nda">
          <Form
            onSubmit={handleSubmit}
            initialValues={formInitialData}
            validationSchema={validationSchema}
          >
            <div className="inputs">
              <FormInput
                name="firstName"
                label="First Name"
                placeholder="First Name"
              />
              <FormInput
                name="lastName"
                label="Last Name"
                placeholder="Last Name"
              />
            </div>
            <div className="inputs">
              <FormInput
                name="phoneNumber"
                label="Phone Number"
                placeholder="Phone Number"
              />
              <FormInput
                name="eemail"
                label="Email"
                placeholder="Email Address"
              />
            </div>
            <FormInput name="age" type="number" label="Age" placeholder="Age" />
            <FormInput
              isPassword
              name="password"
              label="Password"
              placeholder="Create Password"
            />
            <FormInput name="address" label="Address" placeholder="Address" />
            <Submit loading={isLoading} title="Submit" />
          </Form>
        </div>
      </div>
    </>
  );
}

export default MFA;
