import { useEffect } from "react";
import { useContext } from "react";
import { useState } from "react";
import urls from "../../api/urls";
import InternContext from "../../contexts/InternContext";
import Error from "../../form/Error";
import useClient from "../../hooks/useClient";
import { canSubmitToday } from "../../utils/dates";
import Button from "../Button";
import Input from "../Input";
import RenderAgreemnt from "./RenderAgreemnt";

function DailyActivity(props) {
  const [time, setTime] = useState(null);
  const [activity, setActivity] = useState(null);
  const { isLoading, get, post } = useClient();
  const [isOpened, setIsOpened] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [description, setDescription] = useState("");
  const [isAble, setAble] = useState(false);
  const { agreement } = useContext(InternContext);
  const getActivity = async () => {
    const { data, error } = await get(urls.interns.activity.get);
    if (error) return;
    setActivity(data.data.activityLog);
  };

  const startActivity = async () => {
    setErrorMessage(null);
    const { error, data } = await post(urls.interns.activity.start, {
      startDate: time,
    });
    if (error) return setErrorMessage(error.message);
    setIsOpened(false);
    setActivity(data.data.activityLog);
  };
  const endActivity = async () => {
    setErrorMessage(null);
    const { error, data } = await post(urls.interns.activity.end, {
      endDate: time,
      description,
    });
    if (error) return setErrorMessage(error.message);
    setIsOpened(false);
    setActivity(data.data.activityLog);
    // setActivity(data)
  };

  useEffect(() => {
    getActivity();
  }, []);

  useEffect(() => {
    if (agreement) {
      setAble(canSubmitToday(agreement.workDays));
    }
  }, [agreement]);

  if (!isAble) return null;
  return (
    <div>
      {isOpened && (
        <div className="modal_pop">
          <div className="cnt_l container containerh">
            <div onClick={() => setIsOpened(false)} className="close_b">
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10 20C8.61667 20 7.31667 19.7373 6.1 19.212C4.88333 18.6873 3.825 17.975 2.925 17.075C2.025 16.175 1.31267 15.1167 0.788 13.9C0.262667 12.6833 0 11.3833 0 10C0 8.61667 0.262667 7.31667 0.788 6.1C1.31267 4.88333 2.025 3.825 2.925 2.925C3.825 2.025 4.88333 1.31233 6.1 0.787C7.31667 0.262333 8.61667 0 10 0C11.3833 0 12.6833 0.262333 13.9 0.787C15.1167 1.31233 16.175 2.025 17.075 2.925C17.975 3.825 18.6873 4.88333 19.212 6.1C19.7373 7.31667 20 8.61667 20 10C20 11.3833 19.7373 12.6833 19.212 13.9C18.6873 15.1167 17.975 16.175 17.075 17.075C16.175 17.975 15.1167 18.6873 13.9 19.212C12.6833 19.7373 11.3833 20 10 20ZM6.4 15L10 11.4L13.6 15L15 13.6L11.4 10L15 6.4L13.6 5L10 8.6L6.4 5L5 6.4L8.6 10L5 13.6L6.4 15Z"
                  fill="#E13665"
                />
              </svg>
            </div>
            <h2>Daily Log</h2>
            <span>
              The start and end times of the activity will be recorded, as well
              as the overall number of hours spent on the activity.
            </span>
            <span>Select time (Discard if you are starting or ending now)</span>
            <Input
              onChange={(e) => {
                const stmps = e.split(":");
                const date = new Date();
                // console.log(stmps);
                date.setHours(parseInt(stmps[0]));
                date.setMinutes(parseInt(stmps[1]));
                date.setMilliseconds(0);
                setTime(date);
              }}
              type="time"
            />
            {activity && (
              <Input
                value={description}
                onChange={setDescription}
                textarea
                placeholder="Work description"
              />
            )}
            <Error
              className="opph"
              touched={errorMessage}
              error={errorMessage}
            />
            <Button
              onClick={activity ? endActivity : startActivity}
              loading={isLoading}
              title="Done"
              className="gradient "
            />
          </div>
        </div>
      )}
      <br />
      <div className="b2bs">
        <Button
          disabled={activity?.endDate}
          className="gradient"
          onClick={() => {
            setIsOpened(true);
          }}
          title={activity ? "End Activity" : "Start Activity"}
        />
        <br />
        <br />
        <RenderAgreemnt className="mobile" />
        <br />
      </div>
    </div>
  );
}

export default DailyActivity;
