import React from "react";
import Title from "./Title";
import students from "../assets/students2.png";
import students2 from "../assets/students3.png";
import Check from "./Check";
import "../css/internbody.css";

const items = [
  "Autonomy & Adaptable timetable",
  "Worthwhile, Latest resume aptitudes, & Make your own office ",
  "Realize new methods to network & Unquestioning multinational access",
  "Environmentally amicable, full stop to workplace politics, & Most reasonable work/life harmony",
];

function InternBody(props) {
  return (
    <div className="container manrope itbody containerh">
      <div className="flex mfc kah ite">
        <div className="nt adhj">
          <Title title="About this Program" />
          <p>
            COVID-19 has become the most explicative and has altered how
            employers, tutors, and pupils interact with the globe. Workspaces
            have become from office buildings to nearly anywhere with the
            internet — and transforming with them is how units work together,
            managers supervise projects, and companies drive decisions. Virtual
            and remote internships are vital in preparing academy students and
            youthful professionals for this new working planet. Further, remote
            internships' individual and career advantages can't be overstated!
          </p>
        </div>
        <div className="ite udan nt">
          <img src={students2} alt="" />
        </div>
      </div>

      <div className="flex mfc ite">
        <div className="nt">
          <img src={students} alt="" />
        </div>
        <div className="nt">
          <Title title="Benefits" />
          <p>
            Take the csPILLAI as your guide and have the advantage and benefits
            of the program.
          </p>

          <br className="mobile" />
          <ul className="ulw ">
            {items.map((i, idx) => (
              <li key={idx}>
                <Check />
                {i}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
}

export default InternBody;
