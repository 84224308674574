import { useState } from "react";
import "../css/input.css";
import Error from "../form/Error";

const RenderInp = ({ textarea, ...props }) => {
  if (textarea) return <textarea {...props} />;
  return <input {...props} />;
};

function Input({
  name,
  textarea,
  type,
  error,
  placeholder,
  touched,
  label,
  onChange = () => {},
  select,
  options = [],
  onBlur,
  isPassword,
  date = true,
  ...props
}) {
  const [isVisible, setIsVisible] = useState(false);
  const [isOpened, setIsOpened] = useState(false);
  const [selected, setSelected] = useState(null);
  return (
    <div className="input  manrope">
      <label htmlFor={name}>{label}</label>
      {select ? (
        <div
          onClick={() => {
            setIsOpened(!isOpened);
            onBlur();
          }}
          className="select flex justify-between align-center"
        >
          {isOpened && (
            <ul className="options">
              {options.map((o, idx) => (
                <li
                  key={idx}
                  onClick={() => {
                    setSelected(o);
                    onChange(o.value);
                  }}
                >
                  {o.label}
                </li>
              ))}
            </ul>
          )}
          <span>{selected ? selected.label : placeholder || label}</span>
          <svg
            style={{ transform: `rotate(-${isOpened ? 180 : 0}deg)` }}
            width="15"
            height="9"
            viewBox="0 0 15 9"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M14.4969 0H0.503126C0.0833986 0 -0.150967 0.441535 0.108966 0.742968L7.10584 8.82646C7.30612 9.05785 7.69175 9.05785 7.89416 8.82646L14.891 0.742968C15.151 0.441535 14.9166 0 14.4969 0Z"
              fill="#535353"
            />
          </svg>
        </div>
      ) : (
        <RenderInp
          {...props}
          onBlur={onBlur}
          onChange={(e) => onChange(e.target.value)}
          textarea={textarea}
          id={name}
          placeholder={placeholder || label}
          type={isPassword ? (isVisible ? "text" : "password") : type}
        />
      )}
      {isPassword && (
        <div
          onClick={() => setIsVisible(!isVisible)}
          className="ptoggle flex justify-center align-center"
        >
          {isVisible ? (
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12 13C10.9 13 10 13.9 10 15C10 16.1 10.9 17 12 17C13.1 17 14 16.1 14 15C14 13.9 13.1 13 12 13ZM18 8H17V6C17 3.24 14.76 1 12 1C9.72 1 7.73 2.54 7.16 4.75C7.02 5.29 7.34 5.83 7.88 5.97C8.41 6.11 8.96 5.79 9.1 5.25C9.44 3.93 10.63 3 12 3C13.65 3 15 4.35 15 6V8H6C4.9 8 4 8.9 4 10V20C4 21.1 4.9 22 6 22H18C19.1 22 20 21.1 20 20V10C20 8.9 19.1 8 18 8ZM18 19C18 19.55 17.55 20 17 20H7C6.45 20 6 19.55 6 19V11C6 10.45 6.45 10 7 10H17C17.55 10 18 10.45 18 11V19Z"
                fill="#323232"
              />
            </svg>
          ) : (
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M18 8H17V6C17 3.24 14.76 1 12 1C9.24 1 7 3.24 7 6V8H6C4.9 8 4 8.9 4 10V20C4 21.1 4.9 22 6 22H18C19.1 22 20 21.1 20 20V10C20 8.9 19.1 8 18 8ZM9 6C9 4.34 10.34 3 12 3C13.66 3 15 4.34 15 6V8H9V6ZM17 20H7C6.45 20 6 19.55 6 19V11C6 10.45 6.45 10 7 10H17C17.55 10 18 10.45 18 11V19C18 19.55 17.55 20 17 20ZM12 17C13.1 17 14 16.1 14 15C14 13.9 13.1 13 12 13C10.9 13 10 13.9 10 15C10 16.1 10.9 17 12 17Z"
                fill="#323232"
              />
            </svg>
          )}
        </div>
      )}
      <Error error={error} touched={touched} />
    </div>
  );
}

export default Input;
