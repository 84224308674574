import "../css/arcs.css";

const list = [
  {
    title: "R&D background",
    value: "27+",
    subtitle: "27+ Years of R&D background",
    bg: "#FFF2F2",
  },
  {
    title: "Tech areas",
    value: "18+",
    subtitle: "18+ Tech areas expertise",
    bg: "#F1F1F1",
  },
  {
    title: "Micro R&D centers",
    value: "50+",
    subtitle: "50+ Micro R&D centers",
    bg: "#E2F3FF",
  },
];

function Arcs({ arcs = list, n = false }) {
  return (
    <div className="container list mfc flex justify-between">
      {arcs.map((l, idx) => (
        <div className="fxx flex justify-center align-center" key={idx}>
          <div
            style={{ backgroundColor: l.bg }}
            className="flex poppins justify-center align-center"
          >
            {l.value || l.svg()}
          </div>
          <span className="mansalva">{l.title}</span>
          <span style={{ textAlign: "center" }} className="poppins">
            {l.subtitle}
          </span>
        </div>
      ))}
    </div>
  );
}

export default Arcs;
