import axios from "axios";
import store from "../utils/store";

const client = axios.create({
  // baseURL: "http://192.168.63.253:8090/api/v1",
  // baseURL: "http://192.168.38.253:8090/api/v1",
  // baseURL: "http://172.20.10.3:8090/api/v1",
  // baseURL: "http://localhost:8090/api/v1",
  // baseURL: "https://cspillai-api.herokuapp.com/api/v1",
  baseURL: "https://cspillai-api.com/api/v1",

  headers: {
    "x-access-token": store.getAccessToken(),
    "x-refresh-token": store.getRefreshToken(),
  },
});

export default client;
