import { useState } from "react";
import * as Yup from "yup";
import urls from "../api/urls";
import FilePicker from "../components/FilePicker";
import "../css/nda.css";
import Form from "../form/Form";
import FormImagePicker from "../form/FormImagePicker";
import FormInput from "../form/FormInput";
import Submit from "../form/Submit";
import useClient from "../hooks/useClient";

const associates = [
  {
    name: "Jeentra Govardhana Rao",
    company: "StartX  grants(OPC) Pvt. Ltd.",
  },
  {
    name: "Shaik Abdul Saleem",
    company: "SAS endowments Pvt. Ltd.",
  },
];

const validationSchema = Yup.object().shape({
  firstName: Yup.string().required().label("First Name"),
  lastName: Yup.string().required().label("Last Name"),
  eemail: Yup.string().required().label("Email").email(),
  password: Yup.string().required().label("Password").min(10),
  phoneNumber: Yup.string().required().label("Phone Number"),
  address: Yup.string().required().label("Address"),
  amount: Yup.number().required().label("Amount"),
  doorNumber: Yup.string().required().label("Door Number"),
  town: Yup.string().required().label("Town/Village"),
  associate: Yup.string().required().label("Associate"),
  bankName: Yup.string().required().label("Bank Name"),
  branchName: Yup.string().required().label("Branch Name"),
  accountNumber: Yup.string().required().label("Account Number"),
  IFSCCode: Yup.string().required().label("IFSC Code"),
});

const formInitialData = {
  firstName: "",
  lastName: "",
  eemail: "",
  password: "",
  phoneNumber: "",
  address: "",
  amount: "",
  doorNumber: "",
  town: "",
  associate: "",
  bankName: "",
  branchName: "",
  accountNumber: "",
  IFSCCode: "",
};

function Loan(props) {
  const [aadhaarFront, setAadhaarFront] = useState(null);
  const [aadhaarBack, setAadhaarBack] = useState(null);
  const [panCard, setPanCard] = useState(null);
  const { post, isLoading } = useClient();
  const handleSubmit = async ({
    eemail: email,
    password,
    firstName,
    lastName,
    address,
    phoneNumber,
    amount,
    town,
    doorNumber,
    associate,
    bankName,
    branchName,
    accountNumber,
    IFSCCode,
  }) => {
    if (!aadhaarBack) return alert("Upload Aahaar card (Back)");
    if (!aadhaarFront) return alert("Upload Aahaar card (Front)");
    if (!panCard) return alert("Upload card");
    const asso = associates.find((a) => a.company === associate);
    try {
      let token = "";
      const signupRes = await post(urls.investors.register, {
        email,
        password,
        firstName,
        lastName,
        phoneNumber,
      });
      if (signupRes.error) {
        const loginRes = await post(urls.investors.login, { email, password });
        token = loginRes.data.data.tokens.accessToken;
        if (loginRes.error)
          return alert(
            "You already have an account associated with this email try using the password you created account with."
          );
      } else {
        token = signupRes.data.data.tokens.accessToken;
      }
      const formData = new FormData();
      formData.append("aadhaarBack", aadhaarBack);
      formData.append("aadhaarFront", aadhaarFront);
      formData.append("panCard", panCard);
      formData.append("address", address);
      formData.append("phoneNumber", phoneNumber);
      formData.append("amount", amount);
      formData.append("doorNumber", doorNumber);
      formData.append("town", town);
      formData.append("associateName", asso.name);
      formData.append("associateCompany", asso.company);
      formData.append("bankName", bankName);
      formData.append("branchName", branchName);
      formData.append("accountNumber", accountNumber);
      formData.append("IFSCCode", IFSCCode);

      const aggRes = await post(urls.investors.loan.signAgreement, formData, {
        headers: {
          "x-access-token": token,
        },
      });
      if (aggRes.error) return alert(aggRes.error.message);
      alert(`An email would be sent to ${email} with a sign URL`);
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div className="container bg containerh">
      <div style={{ height: 60 }}></div>
      <div className=" form_nda">
        <Form
          onSubmit={handleSubmit}
          initialValues={formInitialData}
          validationSchema={validationSchema}
        >
          <div className="inputs">
            <FormInput
              name="firstName"
              label="First Name"
              placeholder="First Name"
            />
            <FormInput
              name="lastName"
              label="Last Name"
              placeholder="Last Name"
            />
          </div>
          <div className="inputs">
            <FormInput
              name="doorNumber"
              label="Door Number"
              placeholder="Door Number"
            />
            <FormInput
              name="town"
              label="Town/Village"
              placeholder="Town/Village"
            />
          </div>
          <div className="inputs">
            <FormInput
              name="phoneNumber"
              label="Phone Number"
              placeholder="Phone Number"
            />
            <FormInput
              name="eemail"
              label="Email"
              placeholder="Email Address"
            />
          </div>
          <FormInput
            name="amount"
            label="Loan Amount"
            placeholder="Loan Amount"
            type="number"
          />
          <FormInput
            name="associate"
            label="Associate"
            placeholder="Associate"
            select
            options={associates.map((a) => ({
              label: a.company,
              value: a.company,
            }))}
          />
          <div className="inputs">
            <FormInput
              name="bankName"
              label="Bank Name"
              placeholder="Bank Name"
            />
            <FormInput
              name="branchName"
              label="Branch Name"
              placeholder="Branch Name"
            />
          </div>
          <div className="inputs">
            <FormInput
              name="accountNumber"
              label="Account Number"
              placeholder="Account Number"
            />
            <FormInput
              name="IFSCCode"
              label="IFSC Code"
              placeholder="IFSC Code"
            />
          </div>
          <div className="inputs">
            <FormInput
              isPassword
              name="password"
              label="Password"
              placeholder="Create Password"
            />
            <FormInput name="address" label="Address" placeholder="Address" />
          </div>
          <FilePicker
            placeholder="Aadhaar Card (Front)"
            name="aadhaarFront"
            onChange={setAadhaarFront}
          />
          <br />
          <FilePicker
            placeholder="Aadhaar Card (Back)"
            name="aadhaarBack"
            onChange={setAadhaarBack}
          />
          <br />
          <FilePicker
            placeholder="Pan Card"
            name="panCard"
            onChange={setPanCard}
          />
          <br />
          <Submit loading={isLoading} title="Submit" />
        </Form>
      </div>
    </div>
  );
}

export default Loan;
