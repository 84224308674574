import Loader from "./Loader";

function Button({ title, className = "", loading, disabled, ...props }) {
  return (
    <button
      {...props}
      disabled={disabled || loading}
      className={`btn btn_bbn manrope ${className} ${
        disabled ? "disabled" : ""
      }`}
    >
      {loading ? <Loader loading /> : title}
    </button>
  );
}

export default Button;
