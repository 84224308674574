import { useState } from "react";
import * as Yup from "yup";
import urls from "../api/urls";
import "../css/nda.css";
import Form from "../form/Form";
import FormInput from "../form/FormInput";
import Submit from "../form/Submit";
import useClient from "../hooks/useClient";

const validationSchema = Yup.object().shape({
  firstName: Yup.string().required().label("First Name"),
  lastName: Yup.string().required().label("Last Name"),
  eemail: Yup.string().required().label("Email").email(),
  password: Yup.string().required().label("Password").min(10),
  phoneNumber: Yup.string().required().label("Phone Number"),
  address: Yup.string().required().label("Address"),
});

const formInitialData = {
  firstName: "",
  lastName: "",
  eemail: "",
  password: "",
  phoneNumber: "",
  address: "",
};

function NDA(props) {
  const [isPopupVisible, setIPopupVisible] = useState(true);
  const { post, isLoading } = useClient();
  const handleSubmit = async ({
    eemail: email,
    password,
    firstName,
    lastName,
    address,
    phoneNumber,
  }) => {
    try {
      let token = "";
      const signupRes = await post(urls.investors.register, {
        email,
        password,
        firstName,
        lastName,
        phoneNumber,
      });
      if (signupRes.error) {
        const loginRes = await post(urls.investors.login, { email, password });
        token = loginRes.data.data.tokens.accessToken;
        if (loginRes.error)
          return alert(
            "You already have an account associated with this email try using the password you created account with."
          );
      } else {
        token = signupRes.data.data.tokens.accessToken;
      }
      const aggRes = await post(
        urls.investors.nda.signAgreement,
        { address, phoneNumber },
        {
          headers: {
            "x-access-token": token,
          },
        }
      );
      if (aggRes.error) return alert(aggRes.error.message);
      alert(`An email would be sent to ${email} with a sign URL`);
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <>
      {isPopupVisible && (
        <div className="popup_invt ndap manrope">
          <div className="splc">
            <div
              onClick={() => {
                setIPopupVisible(false);
              }}
              className="close_pop"
            >
              Close
            </div>
            <p>
              Hi, there! <br /> "In the interest of preserving useful governance
              with prospective investors and associates, we're inviting that
              anyone closely involved with this startup at this early stage
              signs an NDA electronically for the sensitive data discharge
              hitherto and henceforth."
            </p>
            <p>
              Please fill in your details and submit them. You'll receive an
              email notification and text messages from our compliance and
              regulatory management partner, PiChain, to initiate the e-signing
              of the NDA.
            </p>
            <p>csPILLAI</p>
          </div>
        </div>
      )}
      <div className="container bg containerh">
        <div style={{ height: 60 }}></div>
        <div className=" form_nda">
          <Form
            onSubmit={handleSubmit}
            initialValues={formInitialData}
            validationSchema={validationSchema}
          >
            <div className="inputs">
              <FormInput
                name="firstName"
                label="First Name"
                placeholder="First Name"
              />
              <FormInput
                name="lastName"
                label="Last Name"
                placeholder="Last Name"
              />
            </div>
            <div className="inputs">
              <FormInput
                name="phoneNumber"
                label="Phone Number"
                placeholder="Phone Number"
              />
              <FormInput
                name="eemail"
                label="Email"
                placeholder="Email Address"
              />
            </div>
            <FormInput
              isPassword
              name="password"
              label="Password"
              placeholder="Create Password"
            />
            <FormInput name="address" label="Address" placeholder="Address" />
            <Submit loading={isLoading} title="Submit" />
          </Form>
        </div>
      </div>
    </>
  );
}

export default NDA;
