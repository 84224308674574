import { Link } from "react-router-dom";
import { polices } from "../../components/Footer";

function Index(props) {
  return (
    <div>
      <p>
        Nothing on this website should be defined as an offer to sell, an act of
        asking to buy an offer, or a proposal for any assurance by csPILLAI or
        any third party. You are singularly responsible for judging whether any
        purchase/investment strategy, safety/security/assurance, or related
        purchase/investment is proper for you based on your investment goals,
        financial situations, and uncertainty threshold. Investors and users
        should discuss judicial, tax, insurance, or financing advice with
        authorized legal specialists and finance advisors. Would you mind
        emailing for additional bits of advice?
      </p>

      <ul className="flex mfc lkm">
        {polices.map((p, idx) => (
          <Link
            key={idx}
            to={p.title
              .toLowerCase()
              .replace(" ", "-")
              .replace(" ", "-")
              .replace(" ", "-")}
          >
            <li>{p.title}</li>
          </Link>
        ))}
      </ul>
    </div>
  );
}

export default Index;
