import { Routes, Route, useLocation } from "react-router-dom";
import "../../css/legal.css";
import C404 from "../404";
import { useEffect } from "react";
import { useState } from "react";
import Title from "../../components/Title";
import PrivacyPolicy from "./PrivacyPolicy";
import Copyright from "./Copyright";
import Disclaimer from "./Disclaimer";
import TermsOfUse from "./TermsOfUse";
import Terms from "./Terms";
import RefundPolicy from "./RefundPolicy";
import Index from "./Index";

const policies = [
  {
    title: "Privacy Policy",
    element: <PrivacyPolicy />,
  },

  {
    title: "Terms Of-Service",
    element: <TermsOfUse />,
    subtitle: "Effective as of May 28, 2020",
  },

  {
    title: "Earnings Disclaimer",
    element: <Disclaimer />,
  },
  {
    title: "Terms and Conditions",
    element: <Terms />,
    last_update: "May 28, 2020",
  },
  {
    title: "Copyright Notice",
    element: <Copyright />,
  },
  {
    title: "Refund Policy",
    element: <RefundPolicy />,
  },
];

function Legal(props) {
  const [current, setCurrent] = useState(null);
  const { pathname } = useLocation();

  useEffect(() => {
    setCurrent(
      policies.find(
        (p) =>
          p.title.toLowerCase().replace(" ", "-").replace(" ", "-") ===
          pathname.replace("/legal/", "")
      )
    );
  }, [pathname]);
  return (
    <div className="container manrope lp containerh">
      <div style={{ height: 60 }}></div>
      <Title title={current?.title} subtitle="Legal" />
      <Routes>
        <Route path="/" element={<Index />} />
        {policies.map((p, idx) => (
          <Route
            key={idx}
            path={`/${p.title
              .toLowerCase()
              .replace(" ", "-")
              .replace(" ", "-")}`}
            element={p.element}
          />
        ))}
        <Route path="*" element={<C404 />} />
      </Routes>
    </div>
  );
}

export default Legal;
