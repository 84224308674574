const urls = {
  investors: {
    register: "/investors/register",
    login: "/investors/login",
    nda: {
      baseUrl: "/nda",
      signAgreement: "/nda/sign-agreement",
    },
    loan: {
      baseUrl: "/loan",
      signAgreement: "/loan/sign-agreement",
    },
    services: {
      invest2enterprise: {
        mfa: {
          signAgreement: "/services/invest2enterprise/sign-mfa",
        },
      },
      invest2exit: {
        refund: "/services/invest2exit/refund",
      },
    },
  },
  interns: {
    baseUrl: "/internship",
    register: "/internship/register",
    login: "/internship/login",
    me: "/internship/me",
    logout: "/internship/logout",
    getAgreement: "/internship/get-agreement",
    signAgreement: "/internship/sign-agreement",
    recoverAccount: "/internship/request-password-reset",
    changePassword: "/internship/profile/change-password",
    chnageResume: "/internship/profile/change-resume",
    changeProfileImage: "/internship/profile/change-image",
    activity: {
      get: "/internship/get-activity",
      start: "/internship/start-activity",
      end: "/internship/end-activity",
      activities: "/internship/get-activities?from=",
    },
    reports: {
      getReports: "/internship/reports/me",
    },
    certificate: {
      baseurl: "/internship/certificates/me",
    },
  },
  supplierNDA: "https://api.pillais.group/v1/contracts/signSupplierNda",
};

export default urls;
