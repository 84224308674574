import { useState } from "react";
import "../../css/dashboard/alert.css";

const alerts = {
  success: {
    renderSvg: () => (
      <svg
        width="10"
        height="7"
        viewBox="0 0 10 7"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1.71875 3.46875L3.90625 5.65625L8.28125 0.96875"
          stroke="white"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    ),
  },

  info: {
    renderSvg: () => (
      <svg
        width="2"
        height="12"
        viewBox="0 0 2 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1 10.45L1 11M1 0L1 6.6L1 0Z"
          stroke="white"
          strokeLinecap="round"
        />
      </svg>
    ),
  },

  warn: {
    renderSvg: () => (
      <svg
        width="15"
        height="15"
        viewBox="0 0 15 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M7.5 10.9375V11.25M7.5 5.625V8.75V5.625Z"
          stroke="white"
          strokeLinecap="round"
        />
        <path
          d="M1.39471 11.8852L6.46846 1.90766C6.91409 1.03078 8.08721 1.03078 8.53221 1.90766L13.6053 11.8852C14.031 12.7252 13.4647 13.7502 12.5722 13.7502H2.42659C1.53471 13.7502 0.967839 12.7252 1.39534 11.8852H1.39471Z"
          stroke="white"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    ),
  },
  error: {
    renderSvg: () => (
      <svg
        width="15"
        height="15"
        viewBox="0 0 15 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M7.5 10.9375V11.25M7.5 5.625V8.75V5.625Z"
          stroke="white"
          strokeLinecap="round"
        />
        <path
          d="M1.39471 11.8852L6.46846 1.90766C6.91409 1.03078 8.08721 1.03078 8.53221 1.90766L13.6053 11.8852C14.031 12.7252 13.4647 13.7502 12.5722 13.7502H2.42659C1.53471 13.7502 0.967839 12.7252 1.39534 11.8852H1.39471Z"
          stroke="white"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    ),
  },
};

function Alert({ message, type, closeItem = () => {}, className = "" }) {
  const [isClosed, setIsClosed] = useState(false);
  if (isClosed) return null;
  return (
    <div className={`alert flex align-center ${type} ${className}`}>
      <div className="svg_in flex justify-center align-center">
        {alerts[type].renderSvg()}
      </div>
      <p>{message}</p>
      <div
        onClick={() => {
          closeItem();
          setIsClosed(true);
        }}
        style={{
          backgroundColor: "transparent",
          marginLeft: "auto",
          cursor: "pointer",
        }}
        className="svg_in flex justify-center align-center"
      >
        <svg
          width="10"
          height="10"
          viewBox="0 0 10 10"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M5 5L9 9M1 9L5 5L1 9ZM9 1L4.99924 5L9 1ZM4.99924 5L1 1L4.99924 5Z"
            stroke="#64607D"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </div>
    </div>
  );
}

export default Alert;
