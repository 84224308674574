import FormSelect from "../../form/FormSelect";
import { useFormikContext } from "formik";
import { departments } from "../../utils/internships";
import FormInput from "../../form/FormInput";
import { useEffect, useState } from "react";

function SelectDepartment(props) {
  const [isOther, setIsOther] = useState(false);
  const { values, setFieldValue } = useFormikContext();

  useEffect(() => {
    if (values["department"] === "Other") {
      setFieldValue("department", "");
      setIsOther(true);
    } else if (
      departments.filter((d) => d !== "Other").includes(values["department"])
    ) {
      setIsOther(false);
    }
  }, [values]);

  return (
    <>
      <FormSelect
        name="department"
        placeholder="Select Department"
        label="Department"
        options={departments.map((d) => ({ value: d, label: d }))}
      />
      {isOther && <FormInput name="department" placeholder="Department" />}
    </>
  );
}

export default SelectDepartment;
