import "../css/internsignup.css";
import Form from "../form/Form";
import FormInput from "../form/FormInput";
import Submit from "../form/Submit";
import Title from "./Title";
import * as Yup from "yup";
import FilePicker from "./FilePicker";
import FormSelect from "../form/FormSelect";
import states from "../utils/states";
import useClient from "../hooks/useClient";
import { useState } from "react";
import urls from "../api/urls";
import store from "../utils/store";
import { useContext } from "react";
import InternContext from "../contexts/InternContext";
import SelectCollege from "./SelectCollege";
import SelectDepartment from "./dashboard/SelectDepartment";
import SelectPosition from "./dashboard/SelectPosition";

const validationSchema = Yup.object().shape({
  firstName: Yup.string().required().label("First Name"),
  lastName: Yup.string().required().label("Last Name"),
  email: Yup.string().required().label(" Email").email(),
  password: Yup.string().required().label("Password"),
  college: Yup.string().required().label("College"),
  address: Yup.string().required().label("Address"),
  state: Yup.string().label("State"),
  phoneNumber: Yup.string().required().label("Phone Number"),
  position: Yup.string().required().label("Position"),
  department: Yup.string().required().label("Department"),
});

const formInitialData = {
  firstName: "",
  lastName: "",
  email: "",
  password: "",
  college: "",
  address: "",
  state: "",
  phoneNumber: "",
  position: "",
  department: "",
  //   firstName: "Ismail",
  //   lastName: "Dalhatu",
  //   email: "ismaildalhatu442@gmail.com",
  //   password: "IsmailDalhatu123?",
  //   college: "MIT",
  //   address: "Home",
  //   state: "Bihar",
  //   phoneNumber: "+2349022314973",
};

const contacts = ["address", "state", "phoneNumber"];

function SignupIntern(props) {
  const { setUser } = useContext(InternContext);
  const [profileImage, setProfileImage] = useState(null);
  const [resume, setResume] = useState(null);
  const { isLoading, post } = useClient();

  const handleSignup = async (data) => {
    const formData = new FormData();
    if (profileImage) {
      formData.append("profileImage", profileImage);
    }
    if (resume) {
      formData.append("resume", resume);
    }

    Object.keys(data).forEach((e) => {
      if (contacts.includes(e)) formData.append(`contact[${e}]`, data[e]);
      else formData.append(e, data[e]);
    });

    const { data: response, error } = await post(
      urls.interns.register,
      formData
    );
    if (error) return alert(error.message);
    else alert(response.message);
    store.setTokens(response.data.tokens);
    setUser(response.data.profile);
  };

  return (
    <div id="formSign" className="manrope container containerh">
      <Title title="Register for our internship" />
      <p className="kadh">
        Fill in the data for profile. It will take a couple of minutes. You only
        need a passport
      </p>
      <br className="mobile" />
      <Form
        onSubmit={handleSignup}
        validationSchema={validationSchema}
        initialValues={formInitialData}
      >
        <div className="formSign containerh container">
          <div className="inputs">
            <FormInput
              label="First Name"
              placeholder="First Name"
              name="firstName"
            />
            <FormInput
              label="Last Name"
              placeholder="Last Name"
              name="lastName"
            />
          </div>
          <div className="inputs">
            <FormInput
              label="Email Address"
              placeholder="Email Address"
              name="email"
              type="email"
            />
            <FormInput
              label="Phone Number"
              placeholder="Phone Number"
              name="phoneNumber"
            />
          </div>
          <div className="inputs">
            <SelectDepartment />
            <SelectPosition />
          </div>
          <div className="inputs">
            <FormInput
              label="Password"
              placeholder="Create Password"
              name="password"
              type="password"
              isPassword
            />
            <FormInput
              label="Address"
              placeholder="Home address"
              name="address"
            />
          </div>
          <div className="inputs">
            <SelectCollege />
            <FormSelect
              options={states.map((s) => ({ value: s, label: s }))}
              label="State"
              placeholder="State"
              name="state"
            />
          </div>
          <FilePicker
            onChange={setProfileImage}
            placeholder="Profile Image"
            t="Image"
            accept="images/*"
            name="profileImage"
          />
          <FilePicker
            onChange={setResume}
            accept="application/pdf"
            placeholder="Resume"
            t="PDF"
            name="resume"
          />
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: 20,
            }}
          >
            <Submit loading={isLoading} title="Create Account" />
          </div>
        </div>
      </Form>
    </div>
  );
}

export default SignupIntern;
