import FormSelect from "../../form/FormSelect";
import { useFormikContext } from "formik";
import { positions } from "../../utils/internships";
import FormInput from "../../form/FormInput";
import { useEffect, useState } from "react";

function SelectPosition(props) {
  const [isOther, setIsOther] = useState(false);
  const { values, setFieldValue } = useFormikContext();

  useEffect(() => {
    if (values["position"] === "Other") {
      setFieldValue("position", "");
      setIsOther(true);
    } else if (
      positions.filter((d) => d !== "Other").includes(values["position"])
    ) {
      setIsOther(false);
    }
  }, [values]);

  return (
    <>
      <FormSelect
        name="position"
        placeholder="Select Position"
        label="Position"
        options={positions.map((d) => ({ value: d, label: d }))}
      />
      {isOther && <FormInput name="position" placeholder="Position" />}
    </>
  );
}

export default SelectPosition;
