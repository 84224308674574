import React from "react";

function Support(props) {
  return (
    <div>
      <h2>Support</h2>
    </div>
  );
}

export default Support;
