function Row({ items = [], head }) {
  return (
    <div className={`row flex ${head ? "head" : ""}`}>
      {items.map(({ title, className = "", render, ...props }, idx) => (
        <div className={className} key={idx}>
          {render && !head ? render({ ...props }) : title}
        </div>
      ))}
    </div>
  );
}

export default Row;
