import React from "react";
import Button from "../components/Button";
import { useFormikContext } from "formik";

export default function Submit({ disabled = false, ...others }) {
  const { handleSubmit, isValid, errors } = useFormikContext();
  return (
    <Button
      {...others}
      onClick={handleSubmit}
      disabled={!isValid || disabled}
      // loading={isSubmitting}
    />
  );
}
