import React from "react";
import { useFormikContext } from "formik";
import Select from "../components/Input";

export default function FormSelect({
  name,
  getItem,
  point,
  control,
  ...others
}) {
  const { setFieldTouched, errors, touched, setFieldValue, values } =
    useFormikContext();
  return (
    // <div className="in_c" style={{ position: "relative", marginBottom: 24 }}>
    <Select
      {...others}
      select
      error={errors[name]}
      touched={touched[name]}
      value={values[name] || null}
      onChange={(value) => {
        setFieldValue(name, value);
        setFieldTouched(name);
        if (control) {
          if (point && values[control].includes(point)) return;
          setFieldValue(control, getItem(value) + values[control]);
        }
      }}
    />
    // <Error e />
    // </div>
  );
}
