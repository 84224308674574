import "../../css/dashboard/nav.css";
import { links3, routes1, routes2 } from "../../utils/routesLinks";
import RenderLogoWithText from "./RenderLogoWithText";
import { Link, useLocation } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import InternContext from "../../contexts/InternContext";
import useClient from "../../hooks/useClient";
import Loader from "../Loader";

function SideBar(props) {
  const { setUser, setRouteTitle } = useContext(InternContext);
  const [active, setActive] = useState("/");
  const { isLoading, logout } = useClient();
  const { pathname } = useLocation();

  const startLogout = async () => {
    const hasLoggedOut = await logout();
    if (!hasLoggedOut) return alert("Unable to logout!");
    setUser(null);
  };

  useEffect(() => {
    setActive(pathname);
    setRouteTitle(
      [...routes1.elms, ...routes2.elms, ...links3].find(
        (r) => r.to === pathname
      )?.title
    );
  }, [pathname]);

  return (
    <nav className="manrope">
      <RenderLogoWithText className="rnm desktop" />

      <h3 className="title desktop">{routes1.title}</h3>
      <ul>
        {routes1.elms.map(({ renderSvg, title, to }, idx) => (
          <Link key={idx} to={to}>
            <li
              className={`flex align-center ${active === to ? "active" : ""}`}
            >
              <div className="svg_l flex justify-center align-center">
                {renderSvg()}
              </div>
              <span className="desktop">{title}</span>
            </li>
          </Link>
        ))}
      </ul>
      <div className="sp"></div>
      <h3 className="title desktop">{routes2.title}</h3>
      <ul>
        {routes2.elms.map(({ renderSvg, title, to }, idx) => (
          <Link key={idx} to={to}>
            <li
              className={`flex align-center ${active === to ? "active" : ""}`}
            >
              <div className="svg_l flex justify-center align-center">
                {renderSvg()}
              </div>
              <span className="desktop">{title}</span>
            </li>
          </Link>
        ))}
      </ul>
      <div className="sp"></div>
      <ul>
        <li
          style={{ cursor: "pointer" }}
          onClick={startLogout}
          className={`flex align-center `}
        >
          <div className="svg_l flex justify-center align-center">
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10.8421 8.72727H5.10008V7.27273H10.8421V0.545455C10.8421 0.400791 10.797 0.262052 10.7168 0.15976C10.6365 0.0574673 10.5277 0 10.4142 0H0.427979C0.314472 0 0.205614 0.0574673 0.125352 0.15976C0.0450905 0.262052 0 0.400791 0 0.545455V15.4545C0 15.5992 0.0450905 15.7379 0.125352 15.8402C0.205614 15.9425 0.314472 16 0.427979 16H10.4142C10.5277 16 10.6365 15.9425 10.7168 15.8402C10.797 15.7379 10.8421 15.5992 10.8421 15.4545V8.72727ZM13.8155 8.72727L11.533 11.6364L12.3401 12.665L16 8L12.3401 3.335L11.533 4.36364L13.8155 7.27273H10.8421V8.72727H13.8155Z"
                fill="#4D5959"
              />
            </svg>
          </div>
          <span className="desktop">
            {isLoading ? <Loader loading className="btn_loader" /> : "Logout"}
          </span>
        </li>
      </ul>
    </nav>
  );
}

export default SideBar;
