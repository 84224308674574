import { useState } from "react";
import client from "../api/client";
import { RequestTypes, responseTypes } from "../api/request";
import urls from "../api/urls";
import store from "../utils/store";

function useClient() {
  const [isLoading, setIsLoading] = useState(false);
  const Request = async (type, ...args) => {
    const response = { error: null, data: null };
    if (!Object.values(RequestTypes).includes(type)) {
      response.error = { message: { message: "Invalid Request Type" } };
      return response;
    }
    try {
      setIsLoading(true);
      const res = await client[type](...args);
      if (res.data.type === responseTypes.error) {
        response.error = res.data;
        return response;
      }
      response.data = res.data;
      // if (res.headers["x-access-token"] && res.headers["x-refresh-token"]) {
      //   store.setAccessToken(res.headers["x-access-token"]);
      //   store.setRefreshToken(res.headers["x-refresh-token"]);
      //   if (!store.getItem("isAccepted")) {
      //     app_context.setTokens({
      //       "x-access-token": res.headers["x-access-token"],
      //       "x-refresh-token": res.headers["x-refresh-token"],
      //     });
      //   }
      // }
    } catch (error) {
      response["error"] = error?.response?.data;
    } finally {
      setIsLoading(false);
    }
    return response;
  };

  const get = (...args) => {
    return Request(RequestTypes.GET, ...args);
  };
  const post = (...args) => {
    return Request(RequestTypes.POST, ...args);
  };
  const put = (...args) => {
    return Request(RequestTypes.PUT, ...args);
  };
  const fdelete = (...args) => {
    return Request(RequestTypes.DELETE, ...args);
  };
  const patch = (...args) => {
    return Request(RequestTypes.PATCH, ...args);
  };

  const restoreUser = async () => {
    const token = store.getAccessToken();
    if (!token) return null;
    const { error, data } = await get(urls.interns.me);
    if (error) return null;
    return data.data.profile;
  };

  const logout = async () => {
    const { error } = await post(urls.interns.logout);
    if (error) return false;
    store.removeToken();
    return true;
  };

  const getAgreement = async () => {
    const { data, error } = await get(urls.interns.getAgreement);
    if (error) return;
    if (!data.data.agreement.initiated) {
      try {
        await post(urls.interns.signAgreement, {
          workDays: data.data.agreement.workDays,
          department: data.data.agreement.department,
          position: data.data.agreement.position,
          endDate: data.data.endDate,
          duties: data.data.agreement.duties,
        });
      } catch (error) {}
    }
    return { ...data.data.agreement, eSign: data.data.eSign };
  };
  const getReports = async () => {
    const { data, error } = await get(urls.interns.reports.getReports);
    if (error) return;
    return data.data.reports;
  };

  return {
    isLoading,
    get,
    post,
    put,
    fdelete,
    patch,
    Request,
    restoreUser,
    logout,
    getAgreement,
    getReports,
  };
}

export default useClient;
