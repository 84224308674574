import React from "react";
import { useContext } from "react";
import { useEffect } from "react";
import InternContext from "../../../contexts/InternContext";
import * as Yup from "yup";
import Form from "../../../form/Form";
import FormSelect from "../../../form/FormSelect";
import { days } from "../../../utils/dates";
import Submit from "../../../form/Submit";
import SelectDepartment from "../../../components/dashboard/SelectDepartment";
import SelectPosition from "../../../components/dashboard/SelectPosition";
import FormInput from "../../../form/FormInput";
import useClient from "../../../hooks/useClient";
import urls from "../../../api/urls";
import AddDuties from "../../../components/dashboard/AddDuties";
import { useState } from "react";
import Error from "../../../form/Error";
import ica from "../../../assets/ica.pdf";

const validationSchema = Yup.object().shape({
  from: Yup.string().required().label("Form"),
  to: Yup.string().required().label("to"),
  endDate: Yup.string().required().label("End Date"),
});

const formInitialData = {
  from: "",
  to: "",
  endDate: "",
};

function SignAgreement(props) {
  const [duties, setDuties] = useState([]);
  const { setRouteTitle } = useContext(InternContext);
  const [errorMessage, setErrorMessage] = useState("");
  const { agreement, user } = useContext(InternContext);
  const { post, isLoading } = useClient();

  const initiateAgreement = async ({
    from,
    to,
    department,
    position,
    endDate,
  }) => {
    const { data, error } = await post(urls.interns.signAgreement, {
      workDays: {
        from,
        to,
      },
      department,
      position,
      endDate,
      duties,
    });
    if (error) return setErrorMessage(error.message);
    alert(data.message);
  };

  useEffect(() => {
    setRouteTitle("Internship Contract Agrement");
  }, []);

  if (!user.verifications.account)
    return (
      <div className="signed">
        <h2>You are not approved to start internship at csPILLAI</h2>
        <br />
        <p>
          An email would be send to {user.email} when you are approved to sign
          agreement
        </p>
      </div>
    );

  if (agreement)
    return (
      <div className="signed">
        <h2>You've already sign the Internship Contract Agreement</h2>
      </div>
    );

  return (
    <div className="containerh internaggform">
      <Form
        validationSchema={validationSchema}
        onSubmit={initiateAgreement}
        initialValues={formInitialData}
      >
        <div className="inputs">
          <FormSelect
            name="from"
            label="Work Days (from)"
            placeholder="From"
            options={days
              .filter((d) => d !== "Sunday" && d !== "Saturday")
              .map((d) => ({ value: d, label: d }))}
          />
          <FormSelect
            name="to"
            label="Work Days (to)"
            placeholder="To"
            options={days
              .filter((d) => d !== "Sunday" && d !== "Saturday")
              .map((d) => ({ value: d, label: d }))}
          />
        </div>
        <FormInput
          name="endDate"
          label="Internship ending date"
          placeholder="Date"
          type="date"
        />
        <AddDuties updateDuties={setDuties} />
        <Error error={errorMessage} touched={errorMessage} />
        <a
          className="btn gradient limkn"
          href={ica}
          target="_blank"
          rel="noopener noreferrer"
        >
          Preview
        </a>
        <Submit loading={isLoading} title="Submit" />
      </Form>
    </div>
  );
}

export default SignAgreement;
